import React, { useRef, useState, useEffect, useContext, useCallback } from 'react';
import { Button } from '../../baseComponents';

import { TwoFactorAuthentication } from '../TwoFactorAuthentication/TwoFactorAuthentication';
import { NewPageIcon } from '../../../assets/icons';
import { useApiClient } from '../../../hooks/useApiClient';
import { configurationContext, myAccountContext, pathContext } from '../../../contexts/contexts';

export const SignInAuth = (props: {
  type: 'account-creation' | 'sign-in' | 'edit' | 'change-mobile' | 'reset-password' | 'delete-account';
}) => {
  const { user, setUser, setAlert, clearAlert, clearPersistentAlert, token, alert } = useContext(myAccountContext);
  const { setUrl } = useContext(pathContext);
  const { configuration } = useContext(configurationContext);

  const { mobileView } = configuration;
  const { type } = props;

  const [codeValidation, setCodeValidation] = useState('');
  const [signInCode, setSignInCode] = useState('');
  const [twoFAMethodValue, setTwoFAMethodValue] = useState('');
  const [useBackupCode, setUseBackupCode] = useState(false);

  const lowerSectionRef = useRef<any>(null);
  const [introText, setIntroText] = useState<any>(<></>);
  const [lowerSection, setLowerSection] = useState<any>(<></>);

  const apiClient = useApiClient();

  const handleCodeChange = (e: any) => {
    setSignInCode(e.target.value);
    if (codeValidation !== '') setCodeValidation('');
  };

  const handleCancel = () => {
    if (alert.message !== '') clearAlert();
    switch (type) {
      case 'account-creation':
        setUrl('/account-creation/select-2fa');
        return;
      case 'edit':
        const clearUnconfirmedData = {
          ...user,
          unconfirmed_mobile: '',
          unconfirmed_two_factor_method: '',
        };
        setUser(clearUnconfirmedData);
        setUrl('/edit-account/select-2fa');
        return;
      case 'change-mobile':
        setUrl('/edit-account/mobile');
        return;
      case 'delete-account':
        handleCancelDelete();
        return;
      default:
        return;
    }
  };

  const handleCancelDelete = () => {
    apiClient
      .post('/account_deletion/cancel')
      .then((res) => {
        setUser(res.data);
      })
      .finally(() => {});
  };

  const validateSignInCode = () => {
    if (signInCode === '') {
      setCodeValidation(`${useBackupCode ? 'Backup' : 'Secure verification'} code cannot be blank`);
    }
  };

  const handleUseBackupCode = (value: boolean) => {
    clearPersistentAlert();
    setCodeValidation('');
    setSignInCode('');
    setUseBackupCode(value);
  };

  const clearValidation = () => {
    setCodeValidation('');
    setSignInCode('');
  };

  const getNewCode = useCallback(() => {
    let endpoint = '';

    switch (type) {
      case 'account-creation':
      case 'edit':
        endpoint = '/authentication/send_second_factor_code?confirming_2fa=true';
        break;
      case 'sign-in':
        endpoint = '/authentication/send_second_factor_code';
        break;
      case 'change-mobile':
        endpoint = '/authentication/send_second_factor_code?confirming_mobile=true';
        break;
      case 'reset-password':
        endpoint = `/password_reset/send_verification_code?token=${token}`;
        break;
      case 'delete-account':
        endpoint = '/account_deletion/resend_second_factor_code';
        break;
    }

    apiClient
      .get(endpoint)
      .then(() => {
        setAlert({
          type: 'success',
          message: 'A new verification code has been sent.',
        });
        clearValidation();
      })
      .catch((err) => {
        console.log(err.response);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const maskPhoneNumber = (value: string | null | undefined) => {
    if (!value) return '';
    const usPhone = value.replace('+1 ', '');
    return (
      usPhone.substring(0, usPhone.length - 4).replace(/[0-9]/g, '*') +
      usPhone.substring(usPhone.length - 4, usPhone.length)
    );
  };

  useEffect(() => {
    if (useBackupCode) {
      setIntroText(<>Please enter your backup code to continue.</>);
      return;
    }

    let twoFAMethodValueString = '';
    let verificationMethod = '';
    let userMobile: string | null | undefined = '';

    switch (type) {
      case 'account-creation':
      case 'edit':
        verificationMethod = user.unconfirmed_two_factor_method!;
        userMobile = user.unconfirmed_mobile;
        break;
      case 'change-mobile':
        verificationMethod = 'Mobile';
        userMobile = user.unconfirmed_mobile;
        break;
      case 'reset-password':
      case 'sign-in':
      case 'delete-account':
        verificationMethod = user.two_factor_method[0];
        userMobile = user.mobile;
        break;
      default:
        break;
    }

    let msg = <></>;

    const resendCode = (
      <button
        onKeyDown={(e) => {
          if (e.key === 'Enter') getNewCode();
        }}
        className="nav-link pl-0 cursor-pointer my-0 mx-0 inline"
        data-testid="request-new-verification"
        onClick={getNewCode}
        tabIndex={0}
      >
        please request a new verification code.
      </button>
    );

    const enterNewMobile = (
      <p className="mt-4">
        If the phone number listed above is incorrect,{' '}
        <span
          data-testid="test-try-again-phone"
          tabIndex={0}
          className="nav-link pl-0 cursor-pointer my-0 mx-0 inline"
          onClick={handleCancel}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleCancel();
          }}
        >
          try entering it again.
        </span>
      </p>
    );

    switch (verificationMethod) {
      case 'App':
        twoFAMethodValueString = 'your authentication app';
        msg = (
          <>
            Please wait for your authentication app to generate a verification code, and then enter the code to
            continue. If you have multiple accounts on your app, use the verification code for USCIS.gov.
          </>
        );
        break;
      case 'Mobile':
        const phone = userMobile!.replace(/^\+[0-9]{1,3}(\s|-)/, '') || '';

        msg = (
          <>
            {`A verification code has been sent to ${maskPhoneNumber(
              phone
            )}. Please enter the code that you received. If you do not receive the code in 10 minutes, `}
            {resendCode}
            {type === 'edit' || type === 'account-creation' || type === 'change-mobile' ? enterNewMobile : null}
          </>
        );
        break;
      case 'Email':
        msg = (
          <>
            {`A verification code has been sent to ${user.email}. Please enter the code that you received. If you do not receive the code in 10 minutes, `}
            <button
              onKeyDown={(e) => {
                if (e.key === 'Enter') getNewCode();
              }}
              className="nav-link pl-0 cursor-pointer my-0 mx-0 inline"
              data-testid="request-new-verification"
              onClick={getNewCode}
              tabIndex={0}
            >
              please request a new verification code.
            </button>
            {type !== 'delete-account' && user['unconfirmed_two_factor_method'] === 'Mobile' && (
              <p className="mt-4">
                If the phone number listed above is incorrect,{' '}
                <span
                  data-testid="test-try-again-phone"
                  tabIndex={0}
                  className="nav-link pl-0 cursor-pointer my-0 mx-0 inline"
                  onClick={handleCancel}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleCancel();
                  }}
                >
                  try entering it again.
                </span>
              </p>
            )}
          </>
        );
        break;
    }

    setTwoFAMethodValue(twoFAMethodValueString);
    setIntroText(msg);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useBackupCode]);

  useEffect(() => {
    const showTryAnotherWay = !mobileView && type === 'sign-in';

    let component = <></>;

    if (useBackupCode) {
      component = (
        <p className="text-default">
          <>If you have lost access to {twoFAMethodValue} and the Backup Code, </>
          <a
            className="nav-link inline-flex items-center px-0"
            ref={lowerSectionRef}
            target="_blank"
            rel="noreferrer"
            href="https://www.uscis.gov/about-us/contact-us"
          >
            Contact Us
            <span className="ml-1">
              <NewPageIcon />
            </span>
          </a>
        </p>
      );
    } else if (showTryAnotherWay) {
      component = (
        <Button
          text="Try another verification method"
          id="other-way-button"
          data-test-id="other-way-button"
          className="text-lg font-semibold"
          type="tertiary"
          onClick={() => handleUseBackupCode(true)}
          ref={lowerSectionRef}
        />
      );
    }

    setLowerSection(component);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introText]);

  // Use more conditional rendering in the jsx vs useEffect
  return (
    <>
      <div className="card mx-auto" data-testid="signin-auth-container">
        <TwoFactorAuthentication
          label={useBackupCode ? 'Backup Code' : 'Verification Code'}
          text={introText}
          value={signInCode}
          handleChange={handleCodeChange}
          handleCancel={handleCancel}
          validate={validateSignInCode}
          validateMsg={codeValidation}
          setUseBackupCode={handleUseBackupCode}
          useBackupCode={useBackupCode}
          type={type}
        >
          {lowerSection}
        </TwoFactorAuthentication>
      </div>
    </>
  );
};
