import { useContext } from 'react';
import { myAccountContext, pathContext } from '../../../contexts/contexts';

export const parameterFromAccountType = (accountType: string) => {
  switch (accountType) {
    case 'self':
      return 'applicant';
    case 'representative':
    case 'paralegal':
      return 'legal';
    case 'registrant':
      return 'company';
    default:
      return 'default';
  }
};

export const LegalText = () => {
  return (
    <div data-testid="invitation-expired-text" className="my-3 pt-3">
      <p>
        Invitations expire 7 days after they are sent. You did not timely accept the invitation to join the Legal Team.
      </p>
      <br />
      <p>
        To receive a new invitation, you should ask the Legal Team's attorney or accredited representative to invite you
        again.
      </p>
    </div>
  );
};

export const CompanyText = () => {
  return (
    <div data-testid="invitation-expired-text" className="my-3 pt-3">
      <p>
        Invitations expire 7 days after they are sent. You did not timely accept the invitation to join the Company
        Group.
      </p>
      <br />
      <p>To receive a new invitation, you should ask the Administrator to invite you again.</p>
    </div>
  );
};

export const ApplicantText = () => {
  const { clearAlert } = useContext(myAccountContext);
  const { setUrl } = useContext(pathContext);

  return (
    <div data-testid="invitation-expired-text" className="my-3 pt-3">
      <p>
        Your invitation link has expired. To continue the account creation process, you must set up a password using the{' '}
        <button
          data-testid="forgot-password-link"
          onClick={() => {
            clearAlert();
            setUrl('/forgot-password');
          }}
          aria-label="forgot password link"
          className="pl-1 text-dhs_light_blue hover:text-dhs_focus_blue hover:underline focus:ring-1 focus:ring-dhs_focus_blue no-underline font-bold mr-auto"
        >
          Forgot Password
        </button>{' '}
        page. You must use the same email address that received the invitation from your legal representative in order
        to complete setting up your online account. Enter this email address on the next screen to complete the password
        creation process and connect with your representative to share draft forms for your review.
      </p>
    </div>
  );
};

export const DefaultText = () => {
  return (
    <div data-testid="invitation-expired-text" className="my-3 pt-3">
      <p>
        It has been at least 7 days since you were invited, and you did not accept the invitation. If the invitation
        expired before you could accept it, request a new invitation to be sent to you.
      </p>
    </div>
  );
};
