import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ValidatedInput } from '../../baseComponents';
import { ErrorMsgsProps, FirstErrorProps, NUMBER_OF_SECURITY_QUESTIONS } from './SetSecurityQuestions';
import './SecurityQuestion.css';

interface SecurityQuestionProps {
  allQuestions: Array<string>;
  selectedQuestions: Array<string>;
  questionNumber: number;
  question: string;
  setQuestion: Function;
  answer: string;
  setAnswer: Function;
  errorMsgs: Array<ErrorMsgsProps>;
  setErrorMsgs: Function;
  errorFocus: FirstErrorProps;
}

export const SecurityQuestion = (props: SecurityQuestionProps) => {
  const {
    allQuestions,
    selectedQuestions,
    questionNumber,
    question,
    setQuestion,
    answer,
    setAnswer,
    errorMsgs,
    setErrorMsgs,
    errorFocus,
  } = props;

  const [options, setOptions] = useState(allQuestions);
  const [appended508Text, setAppended508Text] = useState<string>('');
  const questionRef = useRef<HTMLSelectElement>(null);
  const answerRef = useRef<HTMLDivElement>(null);

  // Handles where to put the focus when there are multiple validation errors. It sets the focus on the
  // question or answer input field IF this instance of SecurityQuestion is the first one that contains a validation error
  useEffect(() => {
    if (errorFocus.index === questionNumber && errorFocus.type === 'question') {
      questionRef.current!.focus();
    }
    if (errorFocus.index === questionNumber && errorFocus.type === 'answer') {
      answerRef.current!.focus();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFocus.index, errorFocus.type, questionNumber]);

  // Handles removing items from the list of options which have already been selected in other instances of SecurityQuestion
  useEffect(() => {
    const updateOptions: Array<string> = [];
    allQuestions.forEach((opt) => {
      // only display questions which have NOT been selected
      if (!selectedQuestions.includes(opt)) {
        updateOptions.push(opt);
      }
      // include the selected question for the current question in the list of options
      if (opt === question) {
        updateOptions.push(opt);
      }
    });
    setOptions(updateOptions);
  }, [allQuestions, question, selectedQuestions]);

  const clearDropdownError = () => {
    if (errorMsgs[questionNumber].question !== '') setErrorMsgs(questionNumber, 'question', '');
  };

  const handleAnswerChange = (e: any) => {
    if (errorMsgs[questionNumber].answer !== '') setErrorMsgs(questionNumber, 'answer', '');
    setAnswer(e, questionNumber);
  };

  useEffect(() => {
    let aria508Text = '';
    if (selectedQuestions[questionNumber] !== '') {
      aria508Text = selectedQuestions[questionNumber];
    } else {
      aria508Text = errorMsgs[questionNumber].question === '' ? 'Select one' : errorMsgs[questionNumber].question;
    }
    setAppended508Text(aria508Text);
  }, [errorMsgs]);

  const SelectQuestionInput = ({
    fieldName,
    options,
    onSelect,
    value,
  }: {
    fieldName: string;
    options: string[];
    onSelect: any;
    value: string;
  }) => {
    return (
      <div data-testid="security-question-component">
        <label className="text-md" htmlFor={fieldName} data-testid={`question-${questionNumber + 1}-label`}>
          {fieldName}
          <span className="text-dhs-error-red pl-1">*</span>
        </label>
        <div>
          <select
            className={`question-drop-down-input ${
              errorMsgs[questionNumber].question !== ''
                ? 'border-3 border-dhs-error-red rounded focus:outline-5 focus:ring-4'
                : 'my-2'
            }`}
            data-testid={`test-${fieldName.toLowerCase().split(' ').join('-')}-dropdown`}
            aria-label={`${fieldName} required ${appended508Text}`}
            name={fieldName}
            onChange={onSelect}
            value={value}
            ref={questionRef}
          >
            {value === '' ? <option>Select One</option> : <></>}
            {options.map((option, idx) => {
              return (
                <option data-testid={`dropdown-option`} aria-label={option} value={option} key={option}>
                  {option}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  };

  const handleSelectedQuestion = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedOption = allQuestions.find((opt) => opt === event.target.value);
    setQuestion(questionNumber, selectedOption);
    setAppended508Text(event.target.value);
    clearDropdownError();
  };

  return (
    <>
      <h2 className="pt-2 pb-2 text-xl">
        Question {questionNumber + 1} of {NUMBER_OF_SECURITY_QUESTIONS}
      </h2>

      <div
        className={
          errorMsgs[questionNumber].question !== ''
            ? 'box-content sticky border-l-4 pl-2 border-dhs-error-red font-semibold'
            : 'h-76 font-semibold'
        }
      >
        {errorMsgs[questionNumber].question !== '' && (
          <div className="text-dhs-error-red m-1 p-0" data-testid="question-error-message">
            {errorMsgs[questionNumber].question}
          </div>
        )}
        <SelectQuestionInput
          fieldName={`Security Question ${questionNumber + 1}`}
          value={question}
          options={options}
          onSelect={handleSelectedQuestion}
        />
      </div>

      <div data-testid="security-answer-input" className="pt-1">
        <h3 className="text-md pt-2" data-testid="answer-label">
          {`Security Answer ${questionNumber + 1}`}
          <span className="text-dhs-error-red pl-1">*</span>
        </h3>
        <ValidatedInput
          label={`Security Answer ${questionNumber + 1}`}
          required
          msg={errorMsgs[questionNumber].answer}
          onChange={(e) => handleAnswerChange(e)}
          value={answer}
          type="text"
          ref={answerRef}
          hideLabel
        />
      </div>
    </>
  );
};
