import React from 'react';
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook';
import FocusTrap from 'focus-trap-react';

interface MainContentCardProps {
  children: any;
  id: string;
  className?: string;
  headingText: string;
  pageTitle?: string;
  setInitialFocus?: true | false;
}

const MainContentCard = (props: MainContentCardProps) => {
  const { children, id, className, headingText, pageTitle, setInitialFocus = false } = props;
  const pageTitleText = pageTitle ?? `USCIS Online Account | ${headingText}`;

  PageTitleHook(pageTitleText);

  const MainContentCardContent = React.forwardRef((props, ref: any) => (
    <div id={id} className={`${className} card mx-auto`} data-testid={`${id}-container`} tabIndex={0} ref={ref}>
      <div className="mx-32 mt-8 mb-6">
        <h1 className="text-2xl my-3 font-normal text-dhs_font_gray">{headingText}</h1>
        {children}
      </div>
    </div>
  ));

  return (
    // adding a focus trap with paused true will put the focus on the first focusable element
    setInitialFocus ? (
      <FocusTrap paused={true}>
        <MainContentCardContent />
      </FocusTrap>
    ) : (
      <MainContentCardContent />
    )
  );
};

export default MainContentCard;
