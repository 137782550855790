import { useEffect } from 'react';

const PageTitleHook = (pageTitle: string) => {
  useEffect(
    () => {
      document.title = pageTitle;
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return;
};

export default PageTitleHook;
