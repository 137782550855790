import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, SectionalAlert, ValidatedInput } from '../../baseComponents';
import QRCode from 'react-qr-code';
import { useApiClient } from '../../../hooks/useApiClient';
import { myAccountContext, pathContext } from '../../../contexts/contexts';
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook';

const AuthApp2faConfirmation = (props: { type: string }) => {
  let nameSpace = '';

  switch (props.type) {
    case 'account-creation':
      nameSpace = 'account-creation';
      break;
    case 'edit':
      nameSpace = 'edit-account';
      break;
    default:
      break;
  }

  PageTitleHook('USCIS Online Account | Enter the secure verification code');
  const { user, alert, setAlert, clearPersistentAlert, setUser, token } = useContext(myAccountContext);
  const { setUrl, userState } = useContext(pathContext);

  const [codeValidation, setCodeValidation] = useState('');
  const [signInCode, setSignInCode] = useState('');
  const [email, setEmail] = useState('');
  const [qrkey, setQrkey] = useState('');
  const [issuer, setIssuer] = useState('');
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [isChange2fa, setIsChange2fa] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const alertRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const apiClient = useApiClient();

  const handleModalCancel = () => {
    setShowQRCodeModal(false);
  };

  const handleHelpModalCancel = () => {
    setShowHelpModal(false);
  };

  const handleCodeChange = (e: any) => {
    clearPersistentAlert();
    if (disabledSubmit) setDisabledSubmit(false);
    setSignInCode(e.target.value);
    setCodeValidation('');
  };

  const handleCancel = () => {
    clearPersistentAlert();
    const clearUnconfirmedData = {
      ...user,
      unconfirmed_mobile: '',
      unconfirmed_two_factor_method: '',
    };
    setUser(clearUnconfirmedData);
    setUrl(`/${nameSpace}/select-2fa`);
  };

  const verifyS2FACode = () => {
    apiClient
      .post('/password_reset/verify_second_factor', {
        code: signInCode,
        token: token,
      })
      .then((res) => {
        setUser(res.data);
        setUrl('/edit-account/change-password');
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setAlert({
            type: 'error',
            message: err.response.data.error,
          });
        }
      });
  };

  const confirm2FACode = () => {
    apiClient
      .post('/users/confirm_second_factor_method', {
        verification_code: signInCode,
      })
      .then((res) => {
        setUser(res.data);
        if (isChange2fa) {
          setAlert({
            type: 'success',
            message: 'Two-step verification successful.',
            shouldPersist: true,
          });
          setUrl('/edit-account');
        } else {
          setUrl('/backup-code');
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setAlert({
            type: 'error',
            message: err.response.data.error,
          });
        }
      });
  };

  const handleSubmit = () => {
    if (signInCode === '') {
      setCodeValidation('Secure verification code cannot be blank');
      inputRef.current!.focus();
    } else {
      setDisabledSubmit(true);
      userState === 'forgot-password' ? verifyS2FACode() : confirm2FACode();
    }
  };

  const handleEnterDown = (e: any) => {
    if (e.key === 'Enter' && !disabledSubmit) {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (!loading) return;
    let url = window.location.origin + '/v1/users/set_second_factor_method';
    if (user['authentication_state'] === 'fully_signed_in' && user['two_factor_method'].length > 0) {
      setIsChange2fa(true);
      url = window.location.origin + '/v1/users/update_second_factor_method';
    }
    apiClient
      .post(url, { method_name: 'App' })
      .then((res) => {
        if (res.status === 200 && res.data['qr_code_options']) {
          const qr_email = encodeURI(res.data['email']);
          const qr_codekey = res.data['qr_code_options'].key;
          const qr_issuer = encodeURI(res.data['qr_code_options'].issuer);
          setEmail(qr_email);
          setQrkey(qr_codekey);
          setIssuer(qr_issuer);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && codeValidation !== '') {
      inputRef.current!.focus();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeValidation]);

  useEffect(() => {
    if (!loading && alert.message !== '') {
      alertRef.current!.focus();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  return loading ? (
    <></>
  ) : (
    <div className="card mx-auto" data-testid="authapp-confirmation-container">
      <Modal
        title="App Authentication Manual Entry"
        visible={showQRCodeModal}
        onCancel={handleModalCancel}
        onOk={handleModalCancel}
        info={false}
        hideCancelButton={true}
        hideOkButton={true}
      >
        <div className="flex flex-col space-y-2 pl-2 sm:pl-8">
          <p>Email: {email}</p>
          <p>Key: {qrkey}</p>
          <p>Please enter your key code in your authentication app.</p>
        </div>
      </Modal>
      <Modal
        title=""
        visible={showHelpModal}
        onCancel={handleHelpModalCancel}
        onOk={handleHelpModalCancel}
        info={false}
        hideCancelButton={true}
        hideOkButton={true}
      >
        <div className="flex flex-col space-y-2 mt-6">
          <h2 className="text-2xl">What is a QR code?</h2>
          <p className="color-light-grey">
            A QR code stores URLs and other information in a series of black and white squares that are read by your
            smartphone camera.
          </p>
          <h2 className="text-2xl">Have a previous QR code for this account?</h2>
          <p className="color-light-grey">
            If you had a previous QR code for this account, try deleting it, and rescan the QR code on top of this page
            using your authentication app. It is possible your app is not time synced correctly. Please refer to your
            app for troubleshooting tips.
          </p>
        </div>
      </Modal>
      <h1 className="card-header">Verification Code</h1>
      <div className="card-body flex flex-col space-y-4">
        {alert.message !== '' && (
          <div className="py-1 mb-8" ref={alertRef} data-testid="sectional-alert" tabIndex={0}>
            <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
          </div>
        )}
        <div className="mb-6 flex flex-col space-y-4">
          <p>
            You requested to use an authentication app to generate a secure verification code to log into your USCIS
            online account.
          </p>
          <p>
            An authentication app is a mobile security application that generates secure verification codes.You can use
            any authentication app you prefer.
          </p>
          <p>Scan this QR code with your authentication app.</p>
        </div>
        <div className="text-center mx-auto sm:mx-0" title="qr code for authentication app">
          <QRCode
            size={256}
            value={`otpauth://totp/${issuer}:${email}?secret=${qrkey}&issuer=${issuer}`}
            viewBox={`0 0 256 256`}
            role="img"
            aria-label="qr code for authentication app"
          />
        </div>
        <Button
          id="authapp-qrcode-help-btn"
          data-testid="test-authapp-qrcode-help-btn"
          text="What if I can’t scan a QR code?"
          className="font-bold sm:w-1/2 sm:text-left"
          type="tertiary"
          onClick={(e) => setShowQRCodeModal(true)}
        />
        <div className="button-container">
          <Button
            id="authapp-need-help-btn"
            data-testid="test-authapp-need-help-btn"
            text="Need Help?"
            className="font-bold"
            type="secondary"
            onClick={(e) => setShowHelpModal(true)}
          />
        </div>
        <div className="py-4">
          <hr />
        </div>
        <p className="stuff">Please enter the secure verification code from your authentication application.</p>
        <div data-testid="twoFA-input" className="my-3 pt-3">
          <ValidatedInput
            label="Secure Verification Code"
            required
            msg={codeValidation}
            onChange={handleCodeChange}
            value={signInCode}
            type={'number'}
            inputMode={'numeric'}
            ref={inputRef}
            onKeyDown={handleEnterDown}
          />
        </div>
        <div className="button-container">
          <Button
            id="authapp-code-submit-btn"
            text="Submit"
            disabled={disabledSubmit}
            onMouseDown={handleSubmit}
            onKeyDown={handleEnterDown}
          />
          <Button id="authapp-cancel-back-btn" text="Cancel" type="cancel" onClick={handleCancel} />
        </div>
      </div>
    </div>
  );
};
export default AuthApp2faConfirmation;
