import { useContext, useEffect, useState } from 'react';
import { useApiClient } from '../../../hooks/useApiClient';
import { configurationContext, myAccountContext, pathContext } from '../../../contexts/contexts';
import { Button } from '../../baseComponents';
import alertIcon from '../../../assets/icons/exclamation-triangle.svg';

const AccountType = () => {
  const { user, setUser } = useContext(myAccountContext);
  const { setUrl } = useContext(pathContext);
  const { configuration, setConfiguration } = useContext(configurationContext);

  const [accountType] = useState(user['account_type']);
  const [selected, setSelected] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [h1btoggleEnabled, seth1btoggleEnabled] = useState(false);

  const apiClient = useApiClient();

  const handleChange = (value: string) => {
    setSelected(value);
    setIsDisabled(false);
  };

  const keyDownHandler = (value: string, e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSelected(value);
      setIsDisabled(false);
    }
  };

  const handleSubmit = (e: any) => {
    setIsDisabled(true);

    let url = window.location.origin + '/v1/users/set_account_type';

    if (selected === 'representative') {
      setUrl('/confirm-representative');
    } else if (selected === 'self') {
      apiClient
        .post(url, { account_type: 'self' })
        .then((res) => {
          setUser(res.data);
          setConfiguration({ ...configuration, accountTypeNeeded: false });
          //In the future we should be sending them directly to the dashboard and let redirection handle navigation.
          setUrl('/');
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      apiClient
        .post(url, { account_type: 'registrant' })
        .then((res) => {
          setUser(res.data);
          setConfiguration({ ...configuration, accountTypeNeeded: false });
          //In the future we should be sending them directly to the dashboard and let redirection handle navigation.
          setUrl('/');
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  useEffect(() => {
    document.title = 'USCIS Online Account | Account type';
  }, []);

  useEffect(() => {
    // if they have an account type setup send them to their dashboard
    if (
      (accountType !== null && accountType !== 'noaccounttype') ||
      (accountType !== 'noaccounttype' && accountType !== null)
    ) {
      setUrl('/dashboard');
    }

    // Shows h1b option if account is enabled
    const toggleName = 'RegistrantAccountTypeEnabled';
    const url = window.location.origin + `/v1/app_settings/enabled?toggle_name=${toggleName}`;

    apiClient
      .get(url)
      .then((res) => {
        if (res.data.enabled === true) {
          seth1btoggleEnabled(true);
        } else {
          seth1btoggleEnabled(false);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card mx-auto" data-testid="account-type-page">
      <h1 className="card-header">Account Type</h1>

      <div className="card-body font-semibold flex flex-col space-y-4">
        <hr />
        <p>
          USCIS only offers certain benefit types for online filing. Please refer to the{' '}
          <a
            className="nav-link external-link cursor-pointer inline break-normal m-0 p-0"
            href="https://www.uscis.gov/file-online"
            target="_blank"
            rel="noreferrer"
          >
            USCIS File Online webpage
          </a>{' '}
          for further guidance.
        </p>
        <p>
          H-1B registrations and Form I-129 H-1B petitions can only be filed using the attorney or accredited
          representative account or a company account.
        </p>
        <h2 className="text-xl">Select an account type:</h2>
        <div
          className={`radio-boxed grid grid-cols-12 cursor-pointer ${
            selected === 'self' ? 'bg-dhs_light_gray' : 'bg-gray'
          }`}
          data-testid="self-option"
          tabIndex={0}
          onClick={() => handleChange('self')}
          onKeyDown={(e) => keyDownHandler('self', e)}
        >
          <input
            className="h-4 mt-2 cursor-pointer display-none"
            data-testid="self-radio-btn"
            tabIndex={-1}
            id="self"
            type="radio"
            value="self"
            checked={selected === 'self'}
            readOnly
          />
          <label htmlFor="self" className="inline-flex col-span-11 cursor-pointer">
            <div>
              <h3 id="self-title" className="text-xl">
                I am an individual applicant, petitioner, requestor, or supporter.
              </h3>
              <div>
                <ul className="list-disc pl-6 pr-3 py-3">
                  <li>
                    <p>I am an individual and want to file an application, petition, or request for myself.</p>
                  </li>
                  <li>
                    <p>I am an individual and want to file Form I-134A as a supporter on behalf of a beneficiary.</p>
                  </li>
                </ul>
              </div>
            </div>
          </label>
        </div>
        <div
          className={`radio-boxed grid grid-cols-12 cursor-pointer ${
            selected === 'representative' ? 'bg-dhs_light_gray' : 'bg-gray'
          }`}
          data-testid="representative-option"
          tabIndex={0}
          onClick={() => handleChange('representative')}
          onKeyDown={(e) => keyDownHandler('representative', e)}
        >
          <input
            className="h-4 mt-2 cursor-pointer display-none"
            data-testid="representative-radio-btn"
            tabIndex={-1}
            id="representative"
            type="radio"
            value="representative"
            checked={selected === 'representative'}
            readOnly
          />
          <label htmlFor="representative" className="inline-flex col-span-11 cursor-pointer">
            <div>
              <h3 id="representative-title" className="text-xl">
                I am a Legal Representative.
              </h3>
              <div>
                <ul className="list-disc pl-6 pr-3 py-3">
                  <li>
                    <p>
                      I am an attorney eligible to practice law in the United States and want to file forms on behalf of
                      clients.
                    </p>
                  </li>
                  <li>
                    <p>
                      I am an accredited representative of a qualified organization that is recognized by the Department
                      of Justice in accordance with 8 CFR part 1292 and want to file forms on behalf of clients.
                    </p>
                  </li>
                </ul>

                <div className="flex flex-row space-x-1">
                  <img src={alertIcon} alt="alert icon" />
                  <b>Note:</b>
                  <span>Do not create a representative account if you are a paralegal.</span>
                </div>
              </div>
            </div>
          </label>
        </div>

        {h1btoggleEnabled && (
          <div
            className={`radio-boxed grid grid-cols-12 cursor-pointer ${
              selected === 'h1bregistrant' ? 'bg-dhs_light_gray' : 'bg-gray'
            }`}
            data-testid="h1bregistrant-option"
            tabIndex={0}
            onClick={() => handleChange('h1bregistrant')}
            onKeyDown={(e) => keyDownHandler('h1bregistrant', e)}
          >
            <input
              className="h-4 mt-2 cursor-pointer display-none"
              tabIndex={-1}
              data-testid="h1bregistrant-radio-btn"
              id="h1bregistrant"
              type="radio"
              value="h1bregistrant"
              checked={selected === 'h1bregistrant'}
              readOnly
            />
            <label htmlFor="h1bregistrant" className="inline-flex col-span-11 cursor-pointer">
              <div>
                <h3 id="h1bregistrant-title" className="text-xl">
                  I am part of an organization or company, a sole proprietor, or an agent.
                </h3>
                <div>
                  <ul className="list-disc pl-6 pr-3 py-3">
                    <li>
                      <p>
                        I am an authorized signatory and I want to submit H-1B registrations and/or file Form I-129 H-1B
                        petitions.
                      </p>
                    </li>
                    <li>
                      <p>
                        I am an authorized signatory and I want to sign H-1B registrations and/or Form I-129 H-1B
                        petitions prepared by my attorney or accredited representative.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </label>
          </div>
        )}

        <div className="button-container mt-8">
          <Button
            id="submit-account-type"
            data-testid="submit-account-type"
            text="Submit"
            onClick={handleSubmit}
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountType;
