import React, { useContext, useEffect, useState } from 'react';
import { pathContext } from '../../../../contexts/contexts';
import { Button, Table } from '../../../baseComponents';
import PageTitleHook from '../../../../utils/PageTitleHook/PageTitleHook';
import { useApiClient } from '../../../../hooks/useApiClient';
import { timestampToDateTime } from '../../../../utils/utils';
import { SpinnerCircle } from '../../../baseComponents';

export const eventsHeaders = [
  {
    title: 'Date',
    key: 'created',
  },
  {
    title: 'Event(s)',
    key: 'event_name',
  },
  {
    title: 'Device',
    key: 'device',
  },
];

export interface RowProps {
  created: string;
  event_name: string;
  device: string;
}

export const AccountActivity = () => {
  PageTitleHook('USCIS Online Account | Account activity');
  const { setUrl } = useContext(pathContext);
  const [formattedRows, setFormattedRows] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const apiClient = useApiClient();

  const formatEventsData = (events: Array<any>) => {
    const rows: Array<RowProps> = [];
    if (events.length > 0) {
      events.forEach((event) => {
        rows.push({
          created: timestampToDateTime(event.created_at),
          event_name: event.humanized_event_name,
          device: event.humanized_device || '',
        });
      });
    }
    setFormattedRows(rows);
  };

  useEffect(() => {
    apiClient.get('events').then((res) => {
      formatEventsData(res.data.events);
      setIsLoading(false);
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReturn = (e: any) => {
    setUrl('/edit-account');
  };
  const handleLinkNavigate = () => {
    setUrl('/edit-account/change-password');
  };

  return isLoading ? (
    <SpinnerCircle />
  ) : (
    <div className="card" data-testid="account-activity-container">
      <h1 className="card-header text-left ">Account Activity</h1>
      <div className="mx-16 border border-y-2 border-gray-200 opacity-30"></div>
      <div className="card-body p-0 flex flex-col space-y-6 my-4">
        <p className="text-md mb-3 font-normal">
          If you see a device or event that you do not recognize, or you suspect someone else may have your account
          password, please consider{' '}
          <span
            tabIndex={0}
            data-testid="test-change-password-link"
            className="nav-link pl-0 cursor-pointer my-0 mx-0 inline"
            onClick={handleLinkNavigate}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleLinkNavigate();
            }}
          >
            changing your password.
          </span>
        </p>
        <p>You have taken the following actions in your account in the last 30 days.</p>
      </div>

      <div className="card-body flex flex-col justify-center items-center mb-4">
        <Table striped title="User events" headers={eventsHeaders} data={formattedRows} />
      </div>

      <div className="button-container card-body px-0 flex flex-col space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0 ">
        <Button
          id="account-activity-cancel-btn"
          data-testid="account-activity-cancel-btn"
          text="Back"
          onClick={handleReturn}
          type="secondary"
        />
      </div>
    </div>
  );
};

export default AccountActivity;
