import axios from 'axios';
import { useContext } from 'react';
import { configurationContext, myAccountContext } from '../contexts/contexts';
import { defaultUser } from '../reducer/myAccountInitialState';

const X_CSRF_TOKEN_KEY = 'xcsrf';

// Till the app loads, we don't want to check for a user, since we clear configuration as a result of no user found, but we need configuration while the app loads.
// Also, we will anyways check the user during app load, so it wouldn't even be necessary to check the user here.
let checkForSignedInUser = false;

let idleTimer = {
  resetTimer: () => {},
};

export const setIdleTimer = (timer: any) => {
  idleTimer = timer;
};

export const setCheckForSignedInUser = (bool: boolean) => {
  checkForSignedInUser = bool;
};

export function isTokenPresent(token: any) {
  return token !== null && typeof token === 'string' && token.trim().length !== 0;
}

export function addTokenHeader() {
  return (config: any) => {
    const token = localStorage.getItem(X_CSRF_TOKEN_KEY);
    if (isTokenPresent(token)) {
      config.headers['X-CSRF-Token'] = token;
    }
    return config;
  };
}

export function grabTokenFromResponse(response: any) {
  const token = response.data.xcsrf;
  if (isTokenPresent(token)) {
    localStorage.setItem(X_CSRF_TOKEN_KEY, token);
  }
}

export const fulfilled = (response: any) => {
  grabTokenFromResponse(response);

  // After a completed call, reset idle timer
  idleTimer.resetTimer();

  return response;
};

// This needs to be outside the component for testing, but can't have access to context, therefore we pass it in.
export const rejected = (error: any, myAccountContext: any, configurationContext: any) => {
  if (error.response.data?.error?.includes('No user found.') && checkForSignedInUser) {
    myAccountContext.setUser(defaultUser);
    configurationContext.setConfiguration({
      ...configurationContext.configuration,
      lg_linked: 0,
      loginDotGovEmail: '',
      loginDotGovUuid: '',
      lg_account_status: '',
    });
  }

  // After a completed call, reset idle timer
  idleTimer.resetTimer();

  grabTokenFromResponse(error.response);
  return Promise.reject(error);
};

export const useApiClient = () => {
  const accountContext = useContext(myAccountContext);
  const configContext = useContext(configurationContext);

  const apiClient = axios.create({
    baseURL: `${window.location.origin}/v1`,
    headers: { 'Content-Type': 'application/json' },
  });

  apiClient.interceptors.request.use(addTokenHeader(), (error) => {
    return Promise.reject(error);
  });

  const rejectedFunction = (error: any) => {
    return rejected(error, accountContext, configContext);
  };

  apiClient.interceptors.response.use(fulfilled, rejectedFunction);

  return apiClient;
};
