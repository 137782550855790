import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../../baseComponents';
import { myAccountContext, pathContext } from '../../../../contexts/contexts';
import { IDPCard } from '../IDPCard';
import { useApiClient } from '../../../../hooks/useApiClient';

export const IDPResults = () => {
  const { setUrl } = useContext(pathContext);
  const { user, setUser } = useContext(myAccountContext);
  const apiClient = useApiClient();

  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState(user.ial_state);

  const handleTakeQuiz = () => {
    setUrl('/idp/question');
  };

  const handleCancel = () => {
    setUser({ ...user, ial_state: 'canceled_quiz' });
  };

  const handleContinue = () => {
    setUser({ ...user, ial_state: 'completed_quiz' });
  };

  useEffect(() => {
    if (user.ial_state === 'failed') {
      const url = window.location.origin + '/v1/idpaas/first_question';
      apiClient
        .get(url)
        .then((res) => {
          if (res.data.ial_state === 'hard_fail') {
            setView('hard_fail');
            setUser(res.data);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.ial_state]);

  return isLoading ? null : (
    <>
      {(view === 'passed' || view === 'verified') && (
        <IDPCard title="Thanks for confirming your identity.">
          <p className="idp-index-paragraph">
            You can now access your USCIS online account to get status updates, update your online account profile, and
            change your U.S. mailing address.
          </p>
          <div className="justify-start flex space-x-4 pt-6 pb-0">
            <Button id="submit-btn" text="Continue" onClick={handleContinue} />
          </div>
        </IDPCard>
      )}
      {view === 'failed' && (
        <IDPCard title="Unfortunately, we could not confirm your identity.">
          <p>You may take this quiz again.</p>
          <p>Before you take the quiz, you may find it helpful to refer to the following documents:</p>
          <ul className="idp-card-bullet">
            <li>Your passport(s)</li>
            <li>A copy of your visa application(s)</li>
            <li>Your Immigrant Data Summary Sheet</li>
          </ul>
          <div className="justify-start flex space-x-4 pt-6 pb-0">
            <Button
              id="submit-btn"
              className="w-72"
              text="Take the Identity Verification Quiz again"
              onClick={handleTakeQuiz}
            />
            <Button id="submit-btn" text="Exit" type="secondary" onClick={handleCancel} />
          </div>
        </IDPCard>
      )}
      {view === 'hard_fail' && (
        <IDPCard title="Unfortunately, we could not confirm your identity.">
          <p>
            You can always track the status of your Green Card through{' '}
            <a
              className="nav-link px-0 items-center mr-auto no-underline hover:underline"
              href="https://egov.uscis.gov/"
            >
              Case Status Online
            </a>
            . You will need your receipt number to get the status of your case. The receipt number will begin with the
            letters "IOE."
          </p>
          <p>
            If you need further assistance, please{' '}
            <a
              className="nav-link px-0 items-center mr-auto no-underline hover:underline"
              href="https://www.uscis.gov/about-us/contact-us"
            >
              Contact Us.
            </a>
          </p>
          <div className="justify-start flex space-x-4 pt-6 pb-0">
            <Button id="submit-btn" text="Go back" type="secondary" onClick={handleCancel} />
          </div>
        </IDPCard>
      )}
    </>
  );
};
