import { useApiClient } from '../../../hooks/useApiClient';
import {
  PasskeyListItem,
  PasskeyRemoval,
  listPasskeys,
  removePasskey,
} from '../../../utils/apiWrappers/PasskeysApiWrapper';
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, SectionalAlert } from '../../baseComponents';
import { pathContext, configurationContext, myAccountContext } from '../../../contexts/contexts';
import './passkeys.css';
import LastUsed from './LastUsed';

export const Passkeys = () => {
  const { configuration } = useContext(configurationContext);
  const { appSettings } = configuration;
  const { setUrl } = useContext(pathContext);
  const apiClient = useApiClient();
  const [passkeysList, setPasskeysList] = useState<Array<PasskeyListItem>>([]);
  const { alert, setAlert, setUser } = useContext(myAccountContext);
  const sectionalAlertRef = useRef<HTMLDivElement>(null);
  PageTitleHook('USCIS Online Account | Manage Passkeys');

  useEffect(() => {
    if (!appSettings.includes('PasskeysEnabled')) {
      setUrl('/edit-account');
    }
  }, []);

  useEffect(() => {
    async function fetchPasskeys() {
      setPasskeysList(await listPasskeys(apiClient));
    }

    fetchPasskeys();
  }, []);

  const handleCancelButton = (e: any) => {
    setUrl('/edit-account');
  };

  const handleNewPasskeyButton = (e: any) => {
    setUrl('/passkeys/register');
  };

  const handlePasskeyActionsButton = (passkey: PasskeyListItem) => {
    setAlert({ type: 'success', message: `You clicked on the button for ${passkey.friendly_name}!` });
  };

  const handleTempRemoveButton = async (passkey: PasskeyListItem) => {
    const removal: PasskeyRemoval = await removePasskey(apiClient, passkey);

    if (removal.success && removal.user) {
      setAlert({ type: 'success', message: 'Your passkey has been successfully removed.' });

      setUser(removal.user);
      setPasskeysList(removal.user.passkeys);
    } else {
      setAlert({ type: 'error', message: 'There was a problem removing your passkey.' });
    }
  };

  return (
    <div className="card" data-testid="manage-passkeys-container">
      <h1 className="card-header text-left" data-testid="page-title">
        Manage Passkeys
      </h1>

      {alert.message !== '' && (
        <div id="sectional-alert" data-testid="sectional-alert" ref={sectionalAlertRef} className="mb-6">
          <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
        </div>
      )}

      <div className="card-body p-0 space-y-6 text-md my-4 mb-8">
        {passkeysList.map((passkey) => (
          <div key={passkey.id} className="grid grid-cols-6 passkey-list-row">
            <div className="col-span-2 pr-1">
              <strong>{passkey.friendly_name}</strong>
            </div>
            <div className="col-span-3">
              <LastUsed timestamp={passkey.last_used_at} />
            </div>
            <div className="text-right col-span-1">
              <Button
                id={`passkey-actions-btn-${passkey.id}`}
                data-testid={`passkey-actions-btn-${passkey.id}`}
                text="..."
                onClick={() => handlePasskeyActionsButton(passkey)}
                type="secondary"
                className="passkey-actions-btn"
              />
              <Button
                id={`temp-remove-btn-${passkey.id}`}
                data-testid={`temp-remove-btn-${passkey.id}`}
                text="remove"
                onClick={() => handleTempRemoveButton(passkey)}
                type="secondary"
                className="temp-remove-btn mt-3"
              />
            </div>
          </div>
        ))}
        <div className="">
          <div className="mb-6">
            <Button
              id="new-passkey-btn"
              data-testid="new-passkey-btn"
              text="New Passkey"
              onClick={handleNewPasskeyButton}
              type="primary"
              className="mr-5"
            />
            <Button id="cancel-btn" data-testid="cancel-btn" text="Cancel" type="cancel" onClick={handleCancelButton} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Passkeys;
