import React, { useReducer } from 'react';
import { testInitialState, defaultAlert } from './myAccountInitialState';

export const actions = {
  SET_USER: 'SET_USER',
  CLEAR_USER: 'CLEAR_USER',
  SET_ALERT: 'SET_ALERT',
  CLEAR_ALERT: 'CLEAR_ALERT',
  CLEAR_PERSISTENT_ALERT: 'CLEAR_PERSISTENT_ALERT',
  SET_SHOW_CREATE_ACCOUNT_ALERT: 'SET_SHOW_CREATE_ACCOUNT_ALERT',
  SET_ACCOUNT_ACTIVITY_EVENTS: 'SET_ACCOUNT_ACTIVITY_EVENTS',
  SET_TOKEN: 'SET_TOKEN',
  SET_SHOW_ANNIVERSARY_LOGO: 'SET_SHOW_ANNIVERSARY_LOGO',
};

export function MyAccountReducer(state: any, action: any) {
  switch (action.type) {
    case actions.SET_USER:
      return { ...state, user: action.value };
    case actions.SET_ALERT:
      return { ...state, alert: action.value };
    case actions.CLEAR_ALERT:
      //  If an alert is persistent, we don't want to clear it.
      if (state.alert.shouldPersist) {
        return state;
      } else {
        return { ...state, alert: defaultAlert };
      }
    case actions.CLEAR_PERSISTENT_ALERT:
      return { ...state, alert: defaultAlert };
    case actions.SET_TOKEN:
      return { ...state, token: action.value };
    case actions.SET_SHOW_ANNIVERSARY_LOGO:
      return { ...state, showAnniversaryLogo: action.value };
    default:
      return { state };
  }
}

const changedUser = {
  ...testInitialState.user,
  email: 'test_email_com',
};

//  ======= Component(s) used only for testing reducer ============
export const TestUserUpdate = () => {
  const [state, dispatch] = useReducer(MyAccountReducer, testInitialState);

  return (
    <div>
      <p data-testid="reducer-user-email">Email: {state.email}</p>
      <button data-testid="update-user-btn" onClick={() => dispatch({ type: 'SET_USER', changedUser })}>
        Update User
      </button>
    </div>
  );
};
