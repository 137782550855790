import React from 'react';
import './idp-card.css';
import { MainContentCard } from '../../baseComponents';

interface IDPCardProps {
  id?: string | null | undefined;
  className?: string | '';
  title?: string | null | undefined;
  children?: any;
}

export const IDPCard = (props: IDPCardProps) => {
  let { id, title, children } = props;

  id = id ?? title?.replaceAll(' ', '-');

  return (
    <MainContentCard {...props} className={'idp-card'} headingText={'Identity Quiz'} id={id!} key={id!}>
      {title ? <h2 className="text-xl">{title}</h2> : null}
      {children}
    </MainContentCard>
  );
};
