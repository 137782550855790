import React, { useContext, useEffect, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Button, SectionalAlert } from '../../baseComponents';
import { configurationContext, myAccountContext, pathContext } from '../../../contexts/contexts';
import { useApiClient } from '../../../hooks/useApiClient';
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook';

// Add margins to the page when printing the backup code
const getPageMargins = () => {
  return `@page { margin: 10em 5em 10em 5em !important; }`;
};

const introMsg =
  'If you lose access to the mobile phone number, the authentication app, or the email address used to establish your two-step authentication method, you can use this backup code to log in to your USCIS online account.  Keep this code secure in a safe place for future use.';

const BackupCode = (props: { modalClose?: Function }) => {
  const { modalClose } = props;
  const { setUrl, userState, setUserState } = useContext(pathContext);
  const { user, setUser, clearAlert } = useContext(myAccountContext);
  const { configuration } = useContext(configurationContext);

  const { mobileView } = configuration;

  const [hasUserData, setHasUserData] = useState(false);

  const apiClient = useApiClient();

  /**
   * In edit mode, the BackupCode component is displayed in a modal, so the header and card container are not used.
   * Edit mode also has an extra button for generating a new backup code
   */

  useEffect(() => {
    setHasUserData(user.email !== '');
  }, [user.email]);

  const handleContinueOrClose = () => {
    clearAlert();
    if (userState === 'edit') {
      modalClose && modalClose();
    } else {
      setUserState('');
      setUrl(user.authentication_state === 'fully_signed_in' ? '/dashboard' : '/set-security-questions');
    }
  };

  const handleGenerateNewCode = () => {
    const url = window.location.origin + '/v1/users/generate_new_backup_code';

    apiClient
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const MainContent = () => {
    PageTitleHook('USCIS Online Account | Backup code');
    return (
      <>
        <div className="font-default pb-5">
          If you lose access to the mobile phone number, the authentication app, or the email address used to establish
          your two-step authentication method, you can use this backup code to log in to your USCIS online account.
        </div>

        <SectionalAlert type="warning">
          <>
            <p>Keep this code secure in a safe place for future use.</p>
            <div className="pt-2">
              <style>{getPageMargins()}</style>
              <h2 className="text-xl pb-1">
                Your backup code is: <b>{user.backup_code}</b>
              </h2>
            </div>
          </>
        </SectionalAlert>

        <div className="button-container pt-6 pb-0">
          {mobileView ? null : (
            <ReactToPrint
              trigger={() => <Button id="print-btn" data-testid="print-btn" text="Print" />}
              content={() => {
                const printBackUpCode = document.createElement('div');
                const introElem = document.createElement('div');
                introElem.classList.add('py-8');
                introElem.appendChild(document.createTextNode(introMsg));
                printBackUpCode.appendChild(introElem);
                printBackUpCode.appendChild(document.createTextNode(`Your backup code is:  ${user.backup_code}`));
                return printBackUpCode;
              }}
            />
          )}

          {userState === 'edit' && (
            <Button
              className="sm:w-52"
              id="generate-new-backup-btn"
              text="Generate New Backup Code"
              type="primary"
              onClick={handleGenerateNewCode}
            />
          )}
          <Button
            id="continue-or-close-btn"
            text={userState === 'edit' ? 'Close' : 'Continue'}
            type="secondary"
            onClick={handleContinueOrClose}
          />
        </div>
      </>
    );
  };

  if (!hasUserData) return null;

  return userState === 'edit' ? (
    <MainContent />
  ) : (
    <div className="card mx-auto" data-testid="backup-code-container">
      <h1 className="card-header">Backup Code</h1>
      <div className="card-body">
        <MainContent />
      </div>
    </div>
  );
};
export default BackupCode;
