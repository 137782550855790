export const pathActions = {
  SET_URL: 'SET_URL',
  SET_USER_STATE: 'SET_USER_STATE',
  SET_REFRESH_USER_DATA: 'SET_REFRESH_USER_DATA',
  SET_HEADER_REF: 'SET_HEADER_REF',
};

export function PathReducer(state: any, action: any) {
  switch (action.type) {
    case pathActions.SET_URL:
      return { ...state, url: action.value };
    case pathActions.SET_USER_STATE:
      return { ...state, userState: action.value };
    case pathActions.SET_HEADER_REF:
      return { ...state, headerRef: action.value };
    default:
      return { state };
  }
}
