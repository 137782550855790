import React, { FunctionComponent } from 'react';
import { FooterMidLogo } from '../FooterMidLogo';
import { FooterMidSocials } from '../FooterMidSocials';

export const FooterMid: FunctionComponent = () => {
  return (
    <div className="bg-dhs-gray-30">
      <div
        data-testid="footer-mid"
        className="max-w-screen-lg lg:mx-auto flex flex-col sm:flex-row h-auto space-y-8 sm:space-y-0 sm:pt-4 justify-start sm:justifty-center md:justify-between sm:items-center sm:space-x-4 sm:pr-8"
      >
        <FooterMidLogo />
        <FooterMidSocials />
      </div>
    </div>
  );
};

export default FooterMid;
