import React from 'react';
import './LegalSection.css';

export const LegalSection = () => {
  return (
    <div data-testid="legal-section">
      <h1 className="text-2xl pt-6 mb-2 font-normal">Legal</h1>
      <div className="flex flex-col space-y-2">
        <a
          target="_blank"
          rel="noreferrer"
          href={`${window.location.origin}/legal-terms#tos-security-consent`}
          aria-label="Department of Homeland Security Consent opens in new tab"
          className="nav-link px-0 external-link mr-auto no-underline hover:underline"
        >
          Department of Homeland Security Consent
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={`${window.location.origin}/legal-terms#tos-dhs-privacy`}
          aria-label="DHS Privacy Notice opens in new tab"
          className="nav-link px-0 external-link mr-auto no-underline hover:underline"
        >
          DHS Privacy Notice
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={`${window.location.origin}/legal-terms#tos-fbi-privacy`}
          aria-label="FBI Privacy Notice opens in new tab"
          className="nav-link px-0 external-link mr-auto no-underline hover:underline"
        >
          FBI Privacy Notice
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={`${window.location.origin}/legal-terms#tos-paperwork-reduction`}
          aria-label="Paperwork Reduction Act Burden Disclosure Notice opens in new tab"
          className="nav-link px-0 external-link mr-auto no-underline hover:underline"
        >
          Paperwork Reduction Act Burden Disclosure Notice
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={`${window.location.origin}/legal-terms#tos-tos`}
          aria-label="Terms of Use opens in new tab"
          className="nav-link px-0 external-link mr-auto no-underline hover:underline"
        >
          Terms of Use
        </a>
      </div>
    </div>
  );
};
