import React, { useEffect, useState, useContext } from 'react';
import { useApiClient } from '../../../../hooks/useApiClient';
import { myAccountContext } from '../../../../contexts/contexts';
import PageTitleHook from '../../../../utils/PageTitleHook/PageTitleHook';
import DeleteAccountSuccess from '../DeleteAccountSuccess/DeleteAccountSuccess';
import DeleteAccountFailure from '../DeleteAccountFailure/DeleteAccountFailure';

interface CaseDetail {
  cases: Array<string>;
  drafts: Array<string>;
}
interface SPFormat {
  title: string;
  approval: number;
  humanized_approval: string;
  reason: string;
  case_detail?: CaseDetail;
}

interface SPApprovals {
  first: SPFormat;
  myeverify: SPFormat;
  'my.uscis': SPFormat;
}

const initSPFormat = {
  'my.uscis': {
    title: 'myUSCIS',
    approval: 0,
    humanized_approval: 'approved',
    reason: '',
    case_detail: {
      cases: [''],
      drafts: [''],
    },
  },
  myeverify: {
    title: 'myEverify',
    approval: 0,
    humanized_approval: 'approved',
    reason: '',
  },
  first: {
    title: 'FIRST',
    approval: 0,
    humanized_approval: 'approved',
    reason: '',
  },
};

const DeleteAccountResponse = () => {
  PageTitleHook('USCIS Online Account | Delete account response');
  const { setAlert, user, setUser } = useContext(myAccountContext);

  const [loading, setLoading] = useState(true);
  const [accountDeleted, setAccountDeleted] = useState<boolean>(false);

  const [spApprovals, setSpApprovals] = useState<SPApprovals>(initSPFormat);

  const apiClient = useApiClient();

  useEffect(() => {
    apiClient
      .post('/users/delete')
      .then((res) => {
        if (res.status === 200) {
          setAccountDeleted(true);
          localStorage.clear();
        }
        setLoading(false);
      })
      .catch((err) => {
        setAccountDeleted(false);
        if (err.response.status === 401 && user['account_type'] !== null) {
          setAlert({
            type: 'error',
            message: err.response.data.error,
          });
        }
        if (err.response.status === 422) {
          setSpApprovals(err.response.data.sp_approvals);
          setUser(err.response.data);
        }
        setLoading(false);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading && accountDeleted && <DeleteAccountSuccess />}
      {!loading && !accountDeleted && <DeleteAccountFailure spApprovals={spApprovals} />}
    </>
  );
};
export default DeleteAccountResponse;
