import React, { useContext, useEffect, useRef, useState } from 'react';
import { useApiClient } from '../../../../hooks/useApiClient';
import { myAccountContext, pathContext } from '../../../../contexts/contexts';
import PageTitleHook from '../../../../utils/PageTitleHook/PageTitleHook';
import { Button, Checkbox, SectionalAlert, ValidatedInput } from '../../../baseComponents';
import { defaultUser } from '../../../../reducer/myAccountInitialState';

const VerifyYourAccount = () => {
  PageTitleHook('USCIS Online Account | Verify your account');
  const { user, setUser, alert, setAlert, clearAlert } = useContext(myAccountContext);
  const { setUrl } = useContext(pathContext);

  const [passwordInputVal, setpasswordInputVal] = useState('');
  const [passwordInputValidationMsg, setPasswordInputValidationMsg] = useState('');
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const showPasswordRef = useRef<HTMLInputElement>(null);
  const [pwdFieldType, setPwdFieldType] = useState('password');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const alertRef = useRef<HTMLDivElement>(null);

  const apiClient = useApiClient();

  const handlePasswordCheckbox = (e: any) => {
    if (e.currentTarget.checked) setPwdFieldType('text');
    else setPwdFieldType('password');
  };

  const handleCancel = () => {
    clearAlert();
    setUrl('/edit-account');
  };

  const handleSubmit = (e: any) => {
    if (passwordInputVal === '') {
      setPasswordInputValidationMsg('Password cannot be blank');
      passwordInputRef.current!.focus();
    } else {
      setDisabledSubmit(true);
      apiClient
        .post('/account_deletion/verify_password', {
          email: user.email,
          password: passwordInputVal,
        })
        .then((res) => {
          setUser(res.data);
          setUrl('/delete-account/verify-2fa-delete-account');
        })
        .catch((err) => {
          const errMessage = err.response.data.error || 'unknown error';

          if (errMessage === 'Incorrect password received.') {
            setAlert({
              type: 'error',
              message: 'Please enter a valid password.',
            });
          } else if (errMessage.includes('You have been signed out due to ')) {
            setAlert({
              type: 'error',
              message: errMessage,
              shouldPersist: true,
            });
            setUser(defaultUser);
          }
        });
    }
  };

  const handleEnterDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const handlePasswordInput = (e: any) => {
    if (alert.message !== '') clearAlert();
    if (disabledSubmit) setDisabledSubmit(false);
    if (passwordInputValidationMsg !== '') setPasswordInputValidationMsg('');
    setpasswordInputVal(e.target.value);
  };

  useEffect(() => {
    if (alert.message !== '') {
      alertRef.current!.focus();
    }
  }, [alert]);

  useEffect(() => {
    clearAlert();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card" data-testid="verify-your-account-container">
      <h1 className="card-header text-left ">Verify Your Account</h1>
      {alert.message !== '' && (
        <div data-testid="sectional-alert-container" ref={alertRef} tabIndex={0}>
          <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
        </div>
      )}

      <div className="mx-16 border border-y-2 border-gray-200 opacity-30"></div>
      <div className="card-body p-0 flex flex-col space-y-6 text-md my-4 mb-8">
        <div className="">
          <div className="mb-6">
            <ValidatedInput
              label="Email"
              required
              msg={``}
              onChange={() => {}}
              value={user.email}
              type="text"
              inputMode="email"
              onKeyDown={handleEnterDown}
              readOnly={true}
              additionalStyling="bg-gray-100 opacity-100 text-black"
            />
          </div>
          <ValidatedInput
            label="Password"
            required
            msg={passwordInputValidationMsg}
            onChange={handlePasswordInput}
            value={passwordInputVal}
            type={pwdFieldType}
            ref={passwordInputRef}
            onKeyDown={handleEnterDown}
          />
          <div className="flex align-right justify-end mr-9">
            <Checkbox className="mt-1" label="Show Password" ref={showPasswordRef} onChange={handlePasswordCheckbox} />
          </div>
        </div>
      </div>
      <div className="button-container mt-4 px-0 flex flex-col space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0 ">
        <Button
          id="submit-button"
          data-testid="test-submit-button"
          text="Submit"
          className="max-w-full px-3 py-2 text-md"
          disabled={disabledSubmit}
          onClick={handleSubmit}
        />
        <Button
          id="cancel-btn"
          data-testid="test-cancel-btn"
          text="Cancel"
          type="cancel"
          className="max-w-full text-md"
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};

export default VerifyYourAccount;
