import React, { useEffect, useRef, useState } from 'react';
import {
  SectionalAlertErrorIcon,
  SectionalAlertInfoIcon,
  SectionalAlertSuccessIcon,
  SectionalAlertWarningIcon,
} from '../../../assets/icons';
import './sectional_alert.css';
interface saProps {
  type: string;
  children: any;
}

export const SectionalAlert = (props: saProps) => {
  const MINIMUM_HEIGHT = 52;
  const NONWRAPPED_PADDING_HEIGHT = 4;
  const { type, children } = props;
  const alertRef = useRef<HTMLDivElement>(null);

  const [isTextWrap, setisTextWrap] = useState(false);
  const icons = new Map();
  icons.set(
    'error',
    <div
      data-testid="error-icon"
      aria-label="error-icon"
      className={`sectional-alert-icon ${isTextWrap ? 'pt-1 pr-2 items-top' : ''}`}
    >
      <SectionalAlertErrorIcon />
    </div>
  );
  icons.set(
    'info',
    <div
      data-testid="info-icon"
      aria-label="info-icon"
      className={`sectional-alert-icon ${isTextWrap ? 'pt-1 pr-2 items-top' : ''}`}
    >
      <SectionalAlertInfoIcon />
    </div>
  );
  icons.set(
    'success',
    <div
      data-testid="success-icon"
      aria-label="success-icon"
      className={`sectional-alert-icon ${isTextWrap ? 'pt-1 pr-2 items-top' : ''}`}
    >
      <SectionalAlertSuccessIcon />
    </div>
  );
  icons.set(
    'warning',
    <div
      data-testid="warning-icon"
      aria-label="warning-icon"
      className={`sectional-alert-icon ${isTextWrap ? 'pt-1 pr-2 items-top' : ''}`}
    >
      <SectionalAlertWarningIcon />
    </div>
  );

  useEffect(() => {
    const t = alertRef.current!;
    if (t.scrollHeight >= MINIMUM_HEIGHT - NONWRAPPED_PADDING_HEIGHT) {
      setisTextWrap(true);
    } else {
      setisTextWrap(false);
    }
  }, []);

  return (
    <div
      id="sectional-alert-message"
      ref={alertRef}
      className={`flex flex-row w-full border-t border-gray-20 rounded-md shadow-lg p-4 ${
        isTextWrap ? 'items-start' : 'items-center'
      } sectional_alert_${type} `}
    >
      {icons.get(type)}
      <div className="sectional-alert-body break-words items-center grow" data-testid="alert-message">
        {children}
      </div>
    </div>
  );
};
