/**
* url:              the route to navigate to
* setUrl:           sets the new route, which kicks off the Redirection.tsx code to navigate to the route
* userState:        'edit' when uses is fully signed in and is editing their account
*                   '' when user is logging in or creating an account
* setUserState:     updates value of userState

* headerRef         ref to move focus back to top of page
* setHeaderRef
*/
export interface PathProps {
  url: string;
  setUrl: Function;
  userState: string;
  setUserState: Function;
  headerRef: any;
  setHeaderRef: Function;
}

export const defaultPath = {
  url: '',
  setUrl: () => {},
  userState: '',
  setUserState: () => {},
  headerRef: null,
  setHeaderRef: () => {},
};

export const testCreatePath = {
  url: '/create-account',
  setUrl: () => {},
  userState: '',
  setUserState: () => {},
  headerRef: null,
  setHeaderRef: () => {},
};

export const testEditPath = {
  url: '/edit-account',
  setUrl: () => {},
  userState: 'edit',
  setUserState: () => {},
  headerRef: null,
  setHeaderRef: () => {},
};

export const initialPathState: PathProps = {
  ...defaultPath,
};

export const testPathInitialState: PathProps = {
  ...defaultPath,
};
