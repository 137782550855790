import React, { FunctionComponent } from 'react';

export const FooterTop: FunctionComponent = () => {
  return (
    <div className="bg-dhs-gray-20">
      <div className="max-w-screen-lg lg:mx-auto flex flex-col sm:h-12 sm:flex-row sm:justify-start">
        <ul className="flex flex-col divide-y-2 sm:divide-y-0 sm:flex-row sm:justify-center sm:items-center ml-8">
          <li>
            <a
              className="outline-none h-14 sm:h-12 pt-3 sm:pt-2 px-4 hover:text-dhs-blue font-bold leading-7 focus:ring-4 focus:ring-dhs-blue-02"
              href="https://www.uscis.gov/records/request-records-through-the-freedom-of-information-act-or-privacy-act"
              rel="noreferrer"
              target="_blank"
              title="FOIA Requests"
            >
              FOIA Requests
            </a>
          </li>
          <li>
            <a
              className="outline-none h-14 sm:h-12 pt-3 sm:pt-2 px-4 hover:text-dhs-blue font-bold leading-7 focus:ring-4 focus:ring-dhs-blue-02"
              href="https://www.e-verify.gov/mye-verify"
              rel="noreferrer"
              target="_blank"
              title="myE-Verify"
            >
              myE-Verify
            </a>
          </li>
          <li>
            <a
              className="outline-none h-14 sm:h-12 pt-3 sm:pt-2 px-4 hover:text-dhs-blue font-bold leading-7 focus:ring-4 focus:ring-dhs-blue-02"
              href="https://my.uscis.gov/"
              rel="noreferrer"
              target="_blank"
              title="myUSCIS"
            >
              myUSCIS
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterTop;
