import React, { useEffect, useState, useContext } from 'react';
import { Button } from '../../../baseComponents';
import { myAccountContext } from '../../../../contexts/contexts';
import PageTitleHook from '../../../../utils/PageTitleHook/PageTitleHook';
import { useApiClient } from '../../../../hooks/useApiClient';

interface CaseDetail {
  cases: Array<string>;
  drafts: Array<string>;
}
interface SPFormat {
  title: string;
  approval: number;
  humanized_approval: string;
  reason: string;
  case_detail?: CaseDetail;
}

interface SPApprovals {
  first: SPFormat;
  myeverify: SPFormat;
  'my.uscis': SPFormat;
}

const initSPFormat = {
  title: '',
  approval: 0,
  humanized_approval: 'approved',
  reason: '',
  case_detail: {
    cases: [''],
    drafts: [''],
  },
};

const PROLOGUE_ACTIVE_ITEMS = 'Your account has one or more active items and cannot be deleted at this time.';
const PROLOGUE_SUBMITTED_TRANSACTIONS =
  'This account has submitted transactions with USCIS. You cannot delete this account.';
const PROLOGUE_DELETE_NEVER_REPRESENTATIVE = 'This account has an associated profile. You cannot delete this account.';
const PROLOGUE_WARNING = 'This account has active transactions with USCIS.';

const ACCOUNT_DELETE_NOT_NOW = 'Your Account Cannot Be Deleted Right Now';
const ACCOUNT_DELETE_NEVER = 'Your Account Cannot Be Deleted';
const FIRST_SUB_HEADING =
  'Before you can delete your account, you must review and/or download documents prior to deleting your account.';

const wrapItemsInQuotes = (items: Array<string>) => {
  const wrapped = items.map((i) => `"${i}"`);
  return wrapped.join(', ').trim();
};

const DisplayUSCIS = (props: { title: string; reason: string; case_detail: CaseDetail | undefined }) => {
  const { title, reason, case_detail } = props;
  return (
    <div className="py-2">
      <h2 className="text-lg pt-4 font-bold text-dhs_font_gray">{title}</h2>
      <p className={`my-2 ${title !== 'myUSCIS' && 'pl-4'}`}>{reason}</p>
      <ul className="pl-12 list-disc">
        {case_detail && case_detail.drafts.length > 0 && (
          <li>{`Drafts: [${wrapItemsInQuotes(case_detail.drafts)}]`}</li>
        )}
        {case_detail && case_detail.cases.length > 0 && <li>{`Cases:  [${wrapItemsInQuotes(case_detail.cases)}]`}</li>}
      </ul>
    </div>
  );
};

const DisplayFirstOrMyEverify = (props: { title: string; reason: string }) => {
  const { title, reason } = props;

  return (
    <div className="py-2">
      <h2 className="text-lg pt-4 pb-2 font-bold text-dhs_font_gray">{title}</h2>
      {title.includes('(FIRST') && <p className="my-2 font-normal">{FIRST_SUB_HEADING}</p>}
      <ul className="pl-12 list-disc">
        <li>{reason}</li>
      </ul>
    </div>
  );
};

const DeleteAccountFailure = (props: { spApprovals: SPApprovals }) => {
  PageTitleHook('USCIS Online Account | Your account cannot be deleted');

  const { spApprovals } = props;
  const { user, setUser } = useContext(myAccountContext);

  const [titleText, setTitleText] = useState('');
  const [mainDescription, setMainDescription] = useState('');
  const [firstData, setFirstData] = useState<SPFormat>(initSPFormat);
  const [myEverifyData, setMyEverifyData] = useState<SPFormat>(initSPFormat);
  const [myUSCISData, setMyUSCISData] = useState<SPFormat>(initSPFormat);
  const [loading, setLoading] = useState(true);
  const apiClient = useApiClient();

  const handleBack = () => {
    apiClient.post('/account_deletion/cancel').then((res) => {
      setUser(res.data);
    });
  };

  const keyDownHandler = (e: any) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      handleBack();
    }
  };

  useEffect(() => {
    formatResponse(spApprovals);
    setLoading(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatResponse = (spApprovalData: SPApprovals) => {
    let canBeDeleted = true;

    for (const [key, value] of Object.entries(spApprovalData)) {
      const approvalStatus = value['humanized_approval'];
      if (approvalStatus === 'denied forever') {
        setTitleText(ACCOUNT_DELETE_NEVER);
        canBeDeleted = false;
        switch (user.account_type) {
          case 'self':
          case 'registrant':
            setMainDescription(PROLOGUE_SUBMITTED_TRANSACTIONS);
            break;
          case 'representative':
            setMainDescription(PROLOGUE_DELETE_NEVER_REPRESENTATIVE);
            break;
          default:
            setMainDescription(PROLOGUE_WARNING);
        }
      } else if (value['humanized_approval'] === 'denied right now' && canBeDeleted) {
        setTitleText(ACCOUNT_DELETE_NOT_NOW);
        setMainDescription(PROLOGUE_ACTIVE_ITEMS);
      }

      if (key === 'my.uscis' && approvalStatus !== 'approved') {
        if (value.case_detail && value.case_detail.drafts.length > 0 && value.case_detail.cases.length === 0)
          setMainDescription(PROLOGUE_WARNING);
        setMyUSCISData({ ...value, title: 'myUSCIS' });
      } else if (key === 'first') {
        setFirstData({ ...value, title: 'Freedom of Information Act Immigration Records System (FIRST)' });
      } else if (key === 'myeverify') {
        setMyEverifyData({ ...value, title: 'myE-Verify' });
      }
    }
  };

  return loading ? null : (
    <div className="card mx-auto" data-testid="delete-account-failure-container">
      <h1 className="text-2xl my-3 font-normal text-dhs_font_gray" data-testid="main-title">
        {titleText}
      </h1>
      <p className="font-normal pb-2">{mainDescription}</p>
      {myUSCISData.humanized_approval !== 'approved' && (
        <DisplayUSCIS title={myUSCISData.title} reason={myUSCISData.reason} case_detail={myUSCISData.case_detail} />
      )}
      {firstData.humanized_approval !== 'approved' && (
        <DisplayFirstOrMyEverify title={firstData.title} reason={firstData.reason} />
      )}
      {myEverifyData.humanized_approval !== 'approved' && (
        <DisplayFirstOrMyEverify title={myEverifyData.title} reason={myEverifyData.reason} />
      )}
      <div className="button-container mt-8">
        <Button
          id="back-to-edit-account-btn"
          text="Back to Edit Account"
          onMouseDown={handleBack}
          onKeyDown={keyDownHandler}
        />
      </div>
    </div>
  );
};
export default DeleteAccountFailure;
