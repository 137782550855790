import React, { MouseEventHandler, forwardRef } from 'react';
import { CollapseIcon, ExpandIcon } from '../../../assets/icons';

export interface AccordionItemProps {
  id: number;
  title: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  body?: any;
}

const AccordionItem = (props: AccordionItemProps, ref: any) => {
  const { title, onClick, open, body, id } = props;

  return (
    <div
      className={`pl-4 hover:bg-dhs-gray-05 ${id === 0 && !open ? 'border-t-2' : ''} ${
        open ? 'bg-dhs-gray-05 border-l-3 border-dhs_light_blue focus:outline-5 focus:ring-4' : 'border-b-2'
      }`}
    >
      <button
        data-testid="accordion-item-button"
        aria-label={`${title} ${open ? 'collapse' : 'expand'}`}
        className="w-full py-4 flex justify-between"
        onClick={onClick}
      >
        <h4 className="text-xl font-medium text-left" data-testid="accordion-title">
          {title}
        </h4>
        <div className="flex justify-end items-center ">
          {open ? <CollapseIcon color="#006699" height="20" /> : <ExpandIcon color="#006699" height="20" />}
        </div>
      </button>
      {open && (
        <div data-testid="accordion-body-content" className="pb-8 text-medium">
          {body}
        </div>
      )}
    </div>
  );
};

export default forwardRef(AccordionItem);
