import './EnvironmentBanner.css';

function getEnvironment() {
  const environments = ['local', 'dev', 'dt', 'pt', 'preview'];
  const origin = window.location.origin;

  for (let i = 0; i < environments.length; i++) {
    if (origin.includes(environments[i])) {
      return environments[i];
    }
  }

  return '';
}

export const EnvironmentBanner = () => {
  const environment = getEnvironment();

  if (!environment) {
    return null;
  }

  return (
    <div
      id="env-banner"
      className={`flex w-48 text-xl font-bold h-10 justify-center align-middle items-center env-color-${environment}`}
    >
      {environment.toUpperCase()}
    </div>
  );
};
