import React, { FunctionComponent } from 'react';
import { FooterBottomLogo } from '../FooterBottomLogo';
import { FooterBottomIframe } from '../FooterBottomIframe';

export const FooterBottom: FunctionComponent = () => {
  return (
    <div className="bg-dhs-blue">
      <div
        data-testid="footer-bottom"
        className="max-w-screen-lg lg:mx-auto flex flex-col px-8 sm:px-24 pb-8 sm:flex-row justify-center items-center sm:space-y-0 sm:space-x-20 lg:space-x-14 sm:items-start lg:w-max"
      >
        <FooterBottomLogo />
        <FooterBottomIframe />
      </div>
    </div>
  );
};

export default FooterBottom;
