import React, { useContext, useEffect, useRef, useState } from 'react';

import { Button, Checkbox, Modal, ValidatedInput } from '../../../baseComponents';
import { validateEmailFormat, EMAIL_NO_ACCENTS_ERROR_MESSAGE } from '../../../../utils/utils';
import { myAccountContext, pathContext } from '../../../../contexts/contexts';

import { useApiClient } from '../../../../hooks/useApiClient';
import PageTitleHook from '../../../../utils/PageTitleHook/PageTitleHook';

const EditRecoveryEmail = () => {
  PageTitleHook('USCIS Online Account | Recovery email');
  const { setAlert, user, setUser } = useContext(myAccountContext);
  const { setUrl } = useContext(pathContext);

  const [inputValidationMsg, setInputValidationMsg] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const initialInput = user.recovery_email ? user.recovery_email.replace(' (unconfirmed)', '') : '';
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordInputValidationMsg, setPasswordInputValidationMsg] = useState('');
  const [pwdFieldType, setPwdFieldType] = useState('password');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const showPasswordRef = useRef<HTMLInputElement>(null);

  const apiClient = useApiClient();

  useEffect(() => {
    setInputValue(initialInput);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handlePasswordCheckbox = (e: any) => {
    if (e.currentTarget.checked) setPwdFieldType('text');
    else setPwdFieldType('password');
  };

  const handlePasswordInput = (e: any) => {
    if (passwordInputValidationMsg !== '') setPasswordInputValidationMsg('');
    if (disabledSubmit) setDisabledSubmit(false);
    setCurrentPassword(e.target.value ? e.target.value.trimStart() : '');
  };

  const handleSubmit = () => {
    if (inputValue.trim() === user.email) {
      setInputValidationMsg('Please use a recovery email that is different from account email.');
      inputRef.current!.focus();
      return;
    }

    let recoveryEmail = inputValue === null ? '' : inputValue.trim();
    const validationMsg = validateEmailFormat(recoveryEmail);
    if (validationMsg !== '' && inputRef.current) {
      setInputValidationMsg(validationMsg);
      inputRef.current!.focus();
      return;
    }
    setDisabledSubmit(true);
    apiClient
      .post('/users/set_recovery_email', {
        recovery_email: recoveryEmail,
        current_password: currentPassword,
      })
      .then(async (res) => {
        const alertMsg = `A USCIS Account recovery confirmation email has been sent to ${inputValue
          .replace(/\u2019/g, "'")
          .trim()}. Please follow the instructions in the email to confirm your recovery email request for USCIS Account. If you do not receive the confirmation email within the next 10 minutes, please return to profile update page to start a new request.`;
        setAlert({
          type: 'success',
          message: alertMsg,
          shouldPersist: true,
        });
        setUser(res.data);
        setUrl('/edit-account');
      })
      .catch((err) => {
        const errorMessage = err.response.data.error;
        processErrorMessage(errorMessage);
        return;
      });
  };

  const processErrorMessage = (errorMessage: string) => {
    if (errorMessage === 'The recovery e-mail address cannot be the same as the primary.') {
      setInputValidationMsg(errorMessage);
      inputRef.current!.focus();
    } else if (errorMessage === 'Invalid e-mail address provided.') {
      setInputValidationMsg(EMAIL_NO_ACCENTS_ERROR_MESSAGE);
      inputRef.current!.focus();
    } else if (errorMessage === 'The password provided is not valid.') {
      setPasswordInputValidationMsg('Your password must match your current password');
      passwordInputRef.current!.focus();
    } else if (errorMessage === 'The current password is required.') {
      setPasswordInputValidationMsg(errorMessage);
      passwordInputRef.current!.focus();
    }
  };

  const handleDeleteModal = () => {
    setOpenModal(true);
  };

  const handleDeleteModalCancel = () => {
    setOpenModal(false);
  };

  const handleDeleteRecoveryEmail = () => {
    setOpenModal(false);
    apiClient
      .post('/recovery_emails/remove', {
        current_password: currentPassword,
      })
      .then(async (res) => {
        if (res.status === 200) {
          const alertMsg = 'Recovery email deleted.';
          setAlert({
            type: 'success',
            message: alertMsg,
            shouldPersist: true,
          });
          setUser(res.data);
        } else if (res.status === 401) {
          setAlert({
            type: 'error',
            message: res.data.error,
          });
        }
        setUrl('/edit-account');
      })
      .catch((err) => {
        const errorMessage = err.response.data.error;
        processErrorMessage(errorMessage);
        return;
      });
  };

  const handleCancel = () => {
    setUrl('/edit-account');
  };

  const handleInput = (e: any) => {
    if (inputValidationMsg !== '') setInputValidationMsg('');
    if (disabledSubmit) setDisabledSubmit(false);
    setInputValue(e.target.value.trimStart());
  };

  const handleEnterDown = (e: any) => {
    if (e.key === 'Enter' && !disabledSubmit) {
      handleSubmit();
    }
  };

  return (
    <div className="card mx-auto" data-testid="edit-recovery-email-container">
      <Modal
        title="Delete Recovery Email"
        visible={openModal}
        onCancel={handleDeleteModalCancel}
        onOk={handleDeleteRecoveryEmail}
        info={false}
      >
        <div className="flex flex-col space-y-2">
          <hr />
          <p className="py-2">
            Are you sure you want to delete the recovery email <b>{initialInput}</b>?
          </p>
        </div>
      </Modal>
      <h1 className="text-2xl pb-4 font-normal text-dhs_font_gray">Edit Recovery Email</h1>
      <hr className="pt-4" />
      <div className="flex flex-col space-y-4">
        <p className="py-2">
          {' '}
          You may provide an optional Recovery Email address, which can be used to reset your password if you lose
          access to your Primary Email account. It will not be used for any other communication from USCIS.
        </p>
        <ValidatedInput
          label="Recovery Email"
          required
          msg={inputValidationMsg}
          onChange={handleInput}
          value={inputValue}
          type="text"
          inputMode="email"
          ref={inputRef}
          onKeyDown={handleEnterDown}
        />
        <div className="pb-4">
          <ValidatedInput
            label="Current Password"
            required
            msg={passwordInputValidationMsg}
            onChange={handlePasswordInput}
            value={currentPassword}
            type={!currentPassword ? 'text' : pwdFieldType} // prevents autofill on render
            ref={passwordInputRef}
            onKeyDown={handleEnterDown}
          />
          <div className="w-full flex justify-end mt-1">
            <Checkbox
              id="checkbox-current-password"
              ariaLabel={'show password checkbox'}
              label="Show Password"
              ref={showPasswordRef}
              onChange={handlePasswordCheckbox}
            />
          </div>
        </div>
      </div>
      <div className="button-container justify-start flex pt-6 pb-0">
        <Button
          id="submit-btn"
          data-testid="submit-btn"
          text="Submit"
          type="primary"
          disabled={disabledSubmit}
          onClick={handleSubmit}
        />
        {user.recovery_email && (
          <Button id="delete-btn" data-testid="delete-btn" text="Delete" type="primary" onClick={handleDeleteModal} />
        )}
        <Button id="cancel-btn" data-testid="cancel-btn" text="Cancel" type="cancel" onClick={handleCancel} />
      </div>
    </div>
  );
};
export default EditRecoveryEmail;
