import React, { Suspense } from 'react';

import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { SpinnerCircle } from '../baseComponents';

import Select2fa from '../pages/Select2fa/Select2fa';
import SoftLocked from '../pages/SoftLocked/SoftLocked';
import TermsOfUsePage from '../pages/TermsOfUse/TermsOfUse';
import CreateAccountTos from '../pages/CreateAccountTos/CreateAccountTos';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import SignIn from '../pages/SignIn/SignIn';
import { Error404 } from '../pages/Error404/Error404';
import { SignInAuth } from '../pages/SignInAuth/SignInAuth';
import CreateAccount from '../pages/CreateAccount/CreateAccount';
import { ConfirmToken } from '../pages/ConfirmToken/ConfirmToken';
import AuthApp2faConfirmation from '../pages/AuthApp2faConfirmation/AuthApp2faConfirmation';
import { SetSecurityQuestions } from '../pages/SetSecurityQuestions/SetSecurityQuestions';
import BackupCode from '../pages/BackupCode/BackupCode';
import AccountType from '../pages/AccountType/AccountType';
import EditAccount from '../pages/EditAccount/EditAccount';
import EditMobile from '../pages/EditAccount/EditAccountPages/EditMobile';
import EditRecoveryEmail from '../pages/EditAccount/EditAccountPages/EditRecoveryEmail';
import LegalRepresentative from '../pages/LegalRepresentative/LegalRepresentative';
import ChangePrimaryEmail from '../pages/EditAccount/EditAccountPages/ChangePrimaryEmail';
import RootLayout from './layouts/RootLayout';
import AccountActivity from '../pages/EditAccount/EditAccountPages/AccountActivity';
import { IDPIndex } from '../pages/IDP/IDPPages/IDPIndex';
import { IDPQuestion } from '../pages/IDP/IDPPages/IDPQuestion';
import { IDPResults } from '../pages/IDP/IDPPages/IDPResults';
import { AnswerSecurityQuestions } from '../pages/SetSecurityQuestions/AnswerSecurityQuestions';
import { PlaceHolder } from '../pages/PlaceHolder/PlaceHolder';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import { ResendConfirmation } from '../pages/ResendConfirmation/ResendConfirmation';
import { InvitationExpired } from '../pages/InvitationExpired/InvitationExpired';
import VerifyYourAccount from '../pages/DeleteAccount/VerifyYourAccount/VerifyYourAccount';
import DeleteAccountResponse from '../pages/DeleteAccount/DeleteAccountResponse/DeleteAccountResponse';
import ConfirmDelete from '../pages/DeleteAccount/ConfirmDelete/ConfirmDelete';
import LoginGovLinked from '../pages/LoginGovLinked/LoginGovLinked';
import LoginGovFaq from '../pages/LoginGovFaq/LoginGovFaq';
import Passkeys from '../pages/Passkeys/Passkeys';
import PasskeysRegister from '../pages/PasskeysRegister/PasskeysRegister';
import InvalidIalState from '../pages/IDP/IDPPages/InvalidIalState';

// Lazy load the CreatePassword component
const CreatePasswordForm = React.lazy(() => import('../pages/CreatePassword/CreatePasswordForm'));
const ChangePasswordForm = React.lazy(() => import('../pages/ChangePassword/ChangePassword'));

const LazyImportPasswordForm = (flag: string, type: string) => (
  <>
    <Suspense
      fallback={
        <div className="card">
          <div className="my-5 py-3 flex flex-col justify-center items-center">
            <SpinnerCircle />
          </div>
        </div>
      }
    >
      {flag === 'create' ? <CreatePasswordForm type={type} /> : <ChangePasswordForm />}
    </Suspense>
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="sign-in" element={<SignIn />} />
      <Route path="invalid-ial-state" element={<InvalidIalState />} />
      <Route path="invalid-ial-token" element={<InvalidIalState error="Invalid user" />} />

      <Route path="create-account" element={<CreateAccount />} />
      <Route path="confirmation/:confirmToken" element={<ConfirmToken type="sign-in" />} />
      <Route path="account-creation/confirmation/:confirmToken" element={<ConfirmToken type="account-creation" />} />
      <Route path="update-email/confirmation/:confirmToken" element={<ConfirmToken type="primary-email" />} />
      <Route path="invitation-expired/:text" element={<InvitationExpired />} />
      <Route path="recovery-email-confirmation/:confirmToken" element={<ConfirmToken type="recovery-email" />} />
      <Route path="reset-password/:confirmToken" element={<ConfirmToken type="reset-password" />} />
      <Route path="resend-confirmation" element={<ResendConfirmation />} />
      <Route path="tech-reset/create-password" element={LazyImportPasswordForm('create', 'tech-reset')} />
      <Route path="account-creation/create-password" element={LazyImportPasswordForm('create', 'account-creation')} />
      <Route path="reset-password/create-password" element={LazyImportPasswordForm('create', 'reset-password')} />
      <Route path="auth" element={<SignInAuth type="sign-in" />} />
      <Route path="reset-password/auth" element={<SignInAuth type="reset-password" />} />
      <Route path="change-mobile/auth" element={<SignInAuth type="change-mobile" />} />
      <Route path="account-creation/auth" element={<SignInAuth type="account-creation" />} />
      <Route path="account-creation/totp" element={<AuthApp2faConfirmation type="account-creation" />} />
      <Route path="account-creation/select-2fa" element={<Select2fa type="account-creation" />} />
      <Route path="soft-locked" element={<SoftLocked />} />
      <Route path="users/type" element={<AccountType />} />
      <Route path="confirm-representative" element={<LegalRepresentative />} />
      <Route path="account-creation/terms-of-use" element={<CreateAccountTos />} />
      <Route path="legal-terms" element={<TermsOfUsePage />} />
      <Route path="account-creation/backup-code" element={<BackupCode />} />
      <Route path="log-in/backup-code" element={<BackupCode />} />
      <Route
        path="account-creation/set-security-questions"
        element={<SetSecurityQuestions type="account-creation" />}
      />
      <Route path="answer-security-questions" element={<AnswerSecurityQuestions />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="edit-account" element={<EditAccount />} />
      <Route path="edit-account/primary-email" element={<ChangePrimaryEmail />} />
      <Route path="edit-account/mobile" element={<EditMobile />} />
      <Route path="edit-account/recovery-email" element={<EditRecoveryEmail />} />
      <Route path="edit-account/change-password" element={LazyImportPasswordForm('change', 'edit')} />
      <Route path="edit-account/account-activity" element={<AccountActivity />} />
      <Route path="edit-account/select-2fa" element={<Select2fa type="edit" />} />
      <Route path="edit-account/auth" element={<SignInAuth type="edit" />} />
      <Route path="edit-account/totp" element={<AuthApp2faConfirmation type="edit" />} />
      <Route path="edit-account/set-security-questions" element={<SetSecurityQuestions type="edit" />} />
      <Route path="delete-account/verify-your-account" element={<VerifyYourAccount />} />
      <Route path="delete-account/verify-2fa-delete-account" element={<SignInAuth type="delete-account" />} />
      <Route path="delete-account/confirm-delete" element={<ConfirmDelete />} />
      <Route path="delete-account/response" element={<DeleteAccountResponse />} />
      <Route path="idp/index" element={<IDPIndex />} />
      <Route path="idp/question" element={<IDPQuestion />} />
      <Route path="idp/results" element={<IDPResults />} />
      <Route path="/login-gov-linked" element={<LoginGovLinked />} />
      <Route path="/login-gov-faq" element={<LoginGovFaq />} />

      <Route path="passkeys">
        <Route index element={<Passkeys />} />
        <Route path="register" element={<PasskeysRegister />} />
      </Route>

      <Route path="/place-holder-page" element={<PlaceHolder />} />
      <Route path="*" element={<Error404 />} />
    </Route>
  )
);

export const Routing = () => {
  return <RouterProvider router={router} />;
};
