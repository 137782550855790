import { GetPasskeysData, PasskeyListItem } from './PasskeyInterfaces';
import { AxiosInstance } from 'axios';

export const listPasskeys = async (apiClient: AxiosInstance): Promise<Array<PasskeyListItem>> => {
  let passkeys: Array<PasskeyListItem> = [];

  try {
    const getPasskeysResponse = await apiClient.get('/webauthn/get_passkeys');
    const getPasskeysData: GetPasskeysData = getPasskeysResponse.data;

    if (getPasskeysData.records?.length) {
      passkeys = getPasskeysData.records;
    }
  } catch (e: any) {
    console.log(e.message);
  }

  return passkeys;
};
