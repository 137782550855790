import React, { FunctionComponent } from 'react';
import './FooterExternalLinks.css';
import { useMediaQuery } from '../../../hooks/hooks';

export const FooterExternalLinks: FunctionComponent = () => {
  const screenSizeSmall = useMediaQuery('(max-width: 640px)');
  const screenSizeLarge = useMediaQuery('(min-width: 1024px)');

  const links = [
    { href: 'https://www.uscis.gov/about-us', text: 'About USCIS' },
    { href: 'https://www.uscis.gov/website-policies/accessibility', text: 'Accessibility' },
    { href: 'https://www.uscis.gov/about-us/budget-planning-and-performance', text: 'Budget and Performance' },
    { href: 'https://www.uscis.gov/website-policies/dhs-component-websites', text: 'DHS Components' },
    {
      href: 'https://www.uscis.gov/records/request-records-through-the-freedom-of-information-act-or-privacy-act',
      text: 'FOIA Requests',
    },
    {
      href: 'https://www.uscis.gov/about-us/equal-employment-opportunity/equal-employment-opportunity-data-posted-pursuant-to-the-no-fear-act',
      text: 'No FEAR Act Data',
    },
    {
      href: 'https://www.uscis.gov/website-policies/privacy-and-legal-disclaimers',
      text: 'Privacy and Legal Disclaimers',
    },
    { href: 'https://www.uscis.gov/sitemap', text: 'Site Map' },
    { href: 'https://www.oig.dhs.gov/', text: 'Office of the Inspector General' },
    { href: 'https://www.whitehouse.gov/', text: 'The White House' },
    { href: 'https://www.usa.gov/', text: 'USA.gov' },
  ];

  let columnsCount = 0;

  if (screenSizeSmall) {
    columnsCount = 1;
  } else if (!screenSizeSmall && !screenSizeLarge) {
    columnsCount = 2;
  } else if (screenSizeLarge) {
    columnsCount = 3;
  }

  const columns: any[] = [];

  for (let i = 0; i < columnsCount; i++) {
    columns.push([]);
  }

  let columnIndex = 0;

  for (let i = 0; i < links.length; i++) {
    if (columns[columnIndex].length >= links.length / columnsCount) {
      columnIndex++;
    }
    columns[columnIndex].push(links[i]);
  }

  return (
    <div
      className="external-links-container w-full h-auto sm:h-64
                      font-normal text-dhs-gray-00 underline underline-offset-2
                      sm:w-auto lg:h-24 lg:gap-y-9 lg:gap-x-2 break-words"
    >
      {columns.map((column, index) => (
        <div className="external-links-column mx-2" key={index} data-testid={`external-links-column-${index + 1}`}>
          {column.map((link: any) => (
            <a target="_blank" className="ext-link sm:my-2" href={link.href} rel="noopener noreferrer" key={link.text}>
              {link.text}
            </a>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FooterExternalLinks;
