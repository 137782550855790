import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useApiClient } from '../../../hooks/useApiClient';
import { NewPageIcon } from '../../../assets/icons';
import DashboardCard, { DashboardCardProps } from './DashboardCard';
import './dashboard.css';
import { myAccountContext, pathContext } from '../../../contexts/contexts';
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook';

const cardDataMap = new Map();
cardDataMap.set('myuscis_url', {
  type: 'myUSCIS',
  buttonText: 'Go to myUSCIS',
  description:
    'Apply for immigration benefits, find tools and resources to assist in preparation for naturalization, and search for doctors in your local community.',
});
cardDataMap.set('first_url', {
  type: 'first',
  buttonText: 'Go to FIRST',
  description: 'Submit, manage and receive Freedom of Information Act (FOIA) requests.',
});
cardDataMap.set('myeverify_url', {
  type: 'myEverify',
  buttonText: 'Go to myE-Verify',
  description:
    'Confirm your records for employment eligibility, track E-Verify or Self-Check case status, and protect your identity by locking your Social Security Number (SSN)',
});
cardDataMap.set('accountrp_url', {
  type: 'Account RP',
  buttonText: 'Go to Account RP',
  description: 'This is the link to the Account RP relying party test tools.',
});
cardDataMap.set('everify_plus_url', {
  type: 'E-Verify+',
  buttonText: 'Go to E-Verify+',
  description:
    'E-Verify+ is only for newly hired employees contacted by their employer to complete the Employment Eligibility Verification, Form I-9, as part of the E-Verify+ process.',
});
cardDataMap.set('editMyAccount', {
  type: 'Edit myAccount',
  buttonText: 'Edit My Account',
  description:
    'Editing your profile includes making changes to your email, password, phone number, security questions, or the two-step verification method and backup code.',
});
const cardOrder = ['myuscis_url', 'first_url', 'myeverify_url', 'everify_plus_url', 'accountrp_url'];

export const Dashboard = () => {
  PageTitleHook('USCIS Online Account | Your USCIS account');
  const { user, setUser } = useContext(myAccountContext);
  const { setUrl, setUserState } = useContext(pathContext);

  const [allUrls, setAllUrls] = useState<any>({});
  const [cards, setCards] = useState<Array<DashboardCardProps>>([]);
  const [cardsCreated, setCardsCreated] = useState(false);
  const [accountType] = useState(user['account_type']);

  const apiClient = useApiClient();

  useEffect(() => {
    let isApiSubscribed = true;
    setUserState('');
    apiClient
      .get('dashboard')
      .then((res) => {
        setUser(res.data);
        if (isApiSubscribed) {
          const allUrls = res.data['urls'];
          setAllUrls(allUrls);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
    return () => {
      isApiSubscribed = false;
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createDashboardCards = useCallback(() => {
    const cards: Array<DashboardCardProps> = [];
    cards.push({ ...cardDataMap.get('editMyAccount'), url: '/edit-account' });
    cardOrder.forEach((urlName) => {
      if (allUrls[urlName]) {
        cards.push({ ...cardDataMap.get(urlName), url: allUrls[urlName] });
      }
    });
    if (accountType === null || accountType === 'noaccounttype') {
      let el = cards.find((el) => el.type === 'myUSCIS');

      let myuscisCardIndex = cards.indexOf(el!);
      cards[myuscisCardIndex] = {
        ...cardDataMap.get('myuscis_url'),
        url: '/users/type',
      };
    }
    setCards(cards);
    setCardsCreated(true);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType, allUrls, setUrl]);

  useEffect(() => {
    if (!cardsCreated && Object.keys(allUrls).length > 0) {
      createDashboardCards();
    }
  }, [allUrls, cardsCreated, createDashboardCards]);

  return (
    <>
      {cards.length !== 0 && (
        <div className="bg-white p-4" data-testid="dashboard-container">
          <div className=" max-w-4xl mx-auto my-0 ">
            <h2 className="text-left text-4xl font-light pt-8">Welcome to your USCIS Account</h2>
            <h3 className="text-xl sm:text-2xl text-left mb-2 mt-4">Please select what you want to do.</h3>
            <div className="dashboard-card-grid gap-4">
              {cards.map((card, idx) => (
                <DashboardCard
                  key={`${card.type}-${idx}`}
                  url={card.url}
                  buttonText={card.buttonText}
                  description={card.description}
                  type={card.type}
                />
              ))}
            </div>
            <p className=" text-left font-light text-2xl pb-6">
              Not sure what service you need? Start at{' '}
              <a
                rel="noreferrer"
                aria-label="USCIS.gov opens in new tab"
                href="https://www.uscis.gov/"
                className="nav-link px-0 inline-flex flex-row items-center "
              >
                uscis.gov
                <span className="ml-1">
                  <NewPageIcon />
                </span>
              </a>{' '}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
