import React, { useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { myAccountContext, pathContext } from '../../../contexts/contexts';
// import { redirectPaths } from '../../Routing/Redirection'
import { useApiClient } from '../../../hooks/useApiClient';
import { exceededMaximumAttempts } from '../../../utils/utils';
import { parameterFromAccountType } from '../InvitationExpired/InvitationExpiredUtils';

export const ConfirmToken = (props: {
  type: 'sign-in' | 'primary-email' | 'recovery-email' | 'reset-password' | 'account-creation';
}) => {
  const { setAlert, user, setUser, setToken } = useContext(myAccountContext);
  const { setUrl, userState, setUserState } = useContext(pathContext);

  const { type } = props;
  const { confirmToken } = useParams();

  const apiClient = useApiClient();

  // Don't kick off the redirection process until token confirmation is complete
  useEffect(() => {
    setUserState('confirming-token');
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userState !== 'confirming-token') return;
    if (user.cis_uuid === '' && type === 'reset-password') {
      handleResetPassword();
    } else if (type === 'sign-in' || type === 'account-creation' || type === 'primary-email') {
      handleSignIn();
    } else if (type === 'recovery-email') {
      handleRecoveryEmail();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);

  const handleResetPassword = () => {
    setToken(confirmToken);
    const url = window.location.origin + `/v1/password_reset/identity_verification_method?token=${confirmToken}`;
    apiClient
      .get(url)
      .then((res) => {
        setUser(res.data);
        if (
          res.data.msg ===
          'Your two-step verification method was originally set to SMS. Because you do not have a valid mobile number associated with your account, your two-step verification method has been automatically switched to e-mail.'
        ) {
          setAlert({
            type: 'info',
            message: res.data.msg,
            shouldPersist: true,
          });
        }
      })
      .catch((err) => {
        if (err.response.data.authentication_state === 'second_factor_soft_locked') {
          setUser(err.response.data);
        } else if (err.response.data.error === 'User has exceeded the maximum number of attempts.') {
          setUserState('');
          handleCatch(exceededMaximumAttempts, 'sign-in', '/sign-in');
        } else {
          handleCatch(err.response.data.error, 'forgot-password', '/forgot-password');
        }
      });
  };

  const handleSignIn = () => {
    const url = window.location.origin + '/v1/users/confirm';
    apiClient
      .post(url, {
        confirmation_token: confirmToken,
      })
      .then((res) => {
        setUserState('');
        if (res.data['authentication_state'] === 'fully_signed_in') {
          setAlert({
            type: 'success',
            message: 'Your email address has been successfully confirmed.',
            shouldPersist: true,
          });
          setUser(res.data);
          setUrl('/edit-account');
          return;
        }
        if (res.data['authentication_state'] === 'tech_support_password_reset') {
          setUser(res.data);
          setUrl('/tech-reset/create-password');
          return;
        }
        if (res.status === 200) {
          if (res.data.msg !== '' && res.data.msg !== undefined) {
            setAlert({
              type: 'success',
              message: res.data.msg,
              shouldPersist: true,
            });
          }
          setUser(res.data);
          if (res.data.authentication_state === 'not_signed_in') {
            setUrl('/sign-in');
          }
        }
      })
      .catch((err) => {
        let nextUrl = '';
        const errMsg = err.response.data.error;
        if (
          (type === 'sign-in' || type === 'account-creation' || type === 'primary-email') &&
          errMsg.includes('Your email address has already been confirmed.')
        ) {
          nextUrl = '/sign-in';
        } else {
          if (err.response.data.api_creation) {
            nextUrl = `/invitation-expired/${parameterFromAccountType(err.response.data.account_type)}`;
          } else {
            nextUrl = '/resend-confirmation';
          }
        }
        handleCatch(errMsg, '', nextUrl);
      });
  };

  const handleRecoveryEmail = () => {
    const url = window.location.origin + '/v1/recovery_emails/confirm';

    apiClient
      .post(url, {
        confirmation_token: confirmToken,
      })
      .then((res) => {
        setUserState('');

        if (res.status === 200) {
          setAlert({
            type: 'success',
            message: res.data.msg,
            shouldPersist: true,
          });

          if (res.data.hasOwnProperty('email')) {
            setUser(res.data);
            setUrl('/edit-account');
          } else {
            setUrl('/sign-in');
          }
        }
      })
      .catch((err) => {
        const nextUrl = user.email === '' ? '/sign-in' : '/edit-account';
        handleCatch(err.response.data.error, '', nextUrl);
      });
  };

  const handleCatch = (message: string | object, nextState: string, nextUrl: string) => {
    setAlert({
      type: 'error',
      message: message,
      shouldPersist: true,
    });
    setUserState(nextState);
    setUrl(nextUrl);
  };

  return <></>;
};
