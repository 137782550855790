import React, { useEffect, useState } from 'react';
import { SectionalAlertErrorIcon, SectionalAlertInfoIcon, SectionalAlertWarningIcon } from '../../../assets/icons';
import './StatusBarSection.css';

type Status = {
  type: string;
  text: string;
};

const getIconByStatusType = (type: string) => {
  switch (type) {
    case 'info':
      return <SectionalAlertInfoIcon />;
    case 'warn':
      return <SectionalAlertWarningIcon />;
    case 'urgent':
      return <SectionalAlertErrorIcon />;
  }
};

export const StatusBarSection = () => {
  const [statuses, setStatuses] = useState<Status[]>([]);

  // In the future statuses should come from configuration context.
  // Do not reevaluate statuses based on configuration.
  useEffect(() => {
    setStatuses([
      {
        type: 'urgent',
        text: 'We are currently experiencing technical difficulties. Some features of the myAccount experience may not work as expected. We apologize for any inconvenience this may cause. We are working to resolve the issues. Please check back soon.',
      },
    ]);
  }, []);

  const handleDismissedPressed = (status: any) => {
    setStatuses(statuses.filter((s) => s !== status));
  };

  const hanldeKeyPressed = (keyPress: any, status: any) => {
    if (keyPress.code === 'Enter' || keyPress.code === 'Space') {
      handleDismissedPressed(status);
    }
  };

  return (
    <div data-testid="status-bar-section-container">
      {statuses.map((status) => {
        return (
          <div className={`status-bar flex justify-center w-full status-${status.type}`} key={status.text}>
            <div className="flex w-full mx-4 my-4 sm:my-6 justify-center max-w-screen-lg mx-4">
              <div className="status-bar-icon overflow-visible flex flex-col pt-1 sm:pt-0 sm:justify-center w-5 sm:w-7">
                {getIconByStatusType(status.type)}
              </div>
              <div className="w-full flex flex-col sm:flex-row justify-between">
                <div className="ml-4 sm:mr-8">{status.text}</div>
                <div className="flex flex-column sm:justify-end ml-4 sm: ml-0 mt-4 sm:mt-0">
                  <button
                    className="status-dismiss-button h-8 w-16 py-1 px-1"
                    onKeyPress={(keyPress) => hanldeKeyPressed(keyPress, status)}
                  >
                    <span
                      className=""
                      onClick={() => {
                        handleDismissedPressed(status);
                      }}
                    >
                      <b>
                        <u>Dismiss</u>
                      </b>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
