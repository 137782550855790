import React, { useContext, useRef, useState } from 'react';
import { LegalSection } from '../../LegalSection/LegalSection';
import { Button, SectionalAlert, ValidatedInput } from '../../baseComponents';
import { configurationContext, myAccountContext, pathContext } from '../../../contexts/contexts';
import { EMAIL_ERROR_MESSAGE, validateEmailFormat, throttleAlert } from '../../../utils/utils';
import { useApiClient } from '../../../hooks/useApiClient';
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook';

const ForgotPassword = () => {
  PageTitleHook('USCIS Online Account | Reset your password for your USCIS account');
  const { alert, setAlert, clearAlert } = useContext(myAccountContext);
  const { setUrl } = useContext(pathContext);
  const { configuration } = useContext(configurationContext);
  const { mobileView } = configuration;

  const [emailInputVal, setEmailInputVal] = useState('');
  const [emailValidationMsg, setEmailValidationMsg] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const emailRef = useRef<HTMLInputElement>(null);
  const alertRef = useRef<HTMLDivElement>(null);

  const apiClient = useApiClient();

  const handleEmailInputChange = (e: any) => {
    clearAlert();
    setEmailValidationMsg('');
    setEmailInputVal(e.target.value);
    setDisabledSubmit(false);
  };

  const validateEmailField = () => {
    let err = '';
    if (emailInputVal === '') {
      setEmailValidationMsg('Please enter a valid email address');
      err = 'Please enter a valid email address';
    } else {
      setEmailValidationMsg(validateEmailFormat(emailInputVal.trim(), true));
      err = validateEmailFormat(emailInputVal.trim(), true); //allow accented characters for forgot password email
    }
    return err;
  };

  const handleSubmit = () => {
    setDisabledSubmit(true);

    let validationErr = validateEmailField();

    if (validationErr !== '') {
      emailRef.current?.focus();
      return;
    }

    apiClient
      .post('/password_reset/request_instructions', {
        email: emailInputVal.trim(),
      })
      .then((res) => {
        setAlert({
          type: 'success',
          message: 'You will receive an email with instructions on how to reset your password in a few minutes.',
          shouldPersist: true,
        });
        setUrl('/sign-in');
      })
      .catch((err) => {
        if (err.response.data.error && err.response.data.error === 'Invalid e-mail address provided.') {
          setEmailValidationMsg(EMAIL_ERROR_MESSAGE);
          emailRef.current?.focus();
          setDisabledSubmit(false);
        }
        if (err.response.status === 400) {
          setUrl('/sign-in');
        }
        if (err.response.status === 429) {
          setAlert(throttleAlert);
          setUrl('/sign-in');
        }
      });
  };

  const handleEnterDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="card mx-auto" data-testid="forgot-password-container">
      <h1 className="text-2xl my-3 font-normal text-dhs_font_gray">Forgot your Password?</h1>
      {alert.message !== '' && (
        <div data-testid="sectional-alert-container" ref={alertRef} tabIndex={0}>
          <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
        </div>
      )}
      <p className="pt-3">
        Password reset instructions will be sent to both your primary and recovery email address (if you have one on
        record).
      </p>

      <div data-testid="email-input" className="my-3 pt-3">
        <ValidatedInput
          label="Email Address"
          required
          msg={emailValidationMsg}
          onChange={handleEmailInputChange}
          value={emailInputVal}
          type="text"
          inputMode="email"
          ref={emailRef}
          onKeyDown={handleEnterDown}
        />
      </div>

      <div className="button-container mt-8 mb-6">
        <Button
          id="submit-btn"
          text="Submit"
          disabled={disabledSubmit}
          onMouseDown={handleSubmit}
          onKeyDown={handleEnterDown}
        />
      </div>
      <div className="flex flex-col">
        <h2 className="text-xl">Already have an account?</h2>
        <a
          href="/sign-in"
          data-testid="sign-in-link"
          onClick={(e) => {
            e.preventDefault();
            if (alert.message !== '') {
              clearAlert();
            }
            setUrl('/sign-in');
          }}
          className="text-dhs_light_blue hover:text-dhs_focus_blue focus:ring-1 focus:ring-dhs_focus_blue no-underline font-bold mt-1 mr-auto"
        >
          Sign In
        </a>
        <a
          data-testid="resend-confirmation"
          href="/resend-confirmation"
          onClick={(e) => {
            e.preventDefault();
            setUrl('/resend-confirmation');
          }}
          className="pt-4 text-dhs_light_blue hover:text-dhs_focus_light_blue hover:underline focus:ring-1 focus:ring-dhs_focus_blue focus:underline mt-1 mr-auto"
        >
          Didn't receive confirmation instructions?
        </a>
      </div>
      {mobileView ? null : (
        <>
          <div className="bg-gray-200 h-px max-w-full mt-6"></div>
          <LegalSection />
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
