import { useState, useContext, useEffect, useRef } from 'react';
import { Modal } from '../baseComponents';
import { configurationContext, myAccountContext } from '../../contexts/contexts';

import { useApiClient } from '../../hooks/useApiClient';
import { defaultUser } from '../../reducer/myAccountInitialState';
import workerTimer from '../../utils/worker-timer';
import { setIdleTimer } from '../../hooks/useApiClient';

// We add on one second to the timer to make sure that the timer has expired on the backend first.
const TIMEOUT_TIME = 20 * 60 * 1000 + 1000;
const SPEEDY_TIMEOUT_TIME = 30 * 1000 + 1000;
const PROMPT_BEFORE_TIMEOUT_TIME = 2 * 60 * 1000;
const SPEEDY_PROMPT_BEFORE_TIMEOUT_TIME = 20 * 1000;

const getTimeSinceLastTimerRefresh = () => {
  return new Date().getTime() - Number(localStorage.getItem('idleTimerLastRefresh'));
};

const getIdleTimerTime = (useSpeedyTimers: boolean, syncedTime: boolean) => {
  const idleTimerLastRefresh = syncedTime ? getTimeSinceLastTimerRefresh() : 0;
  return useSpeedyTimers ? SPEEDY_TIMEOUT_TIME - idleTimerLastRefresh : TIMEOUT_TIME - idleTimerLastRefresh;
};

const getPromptTimerTime = (useSpeedyTimers: boolean, idleTimerTime: number) => {
  return useSpeedyTimers
    ? idleTimerTime - SPEEDY_PROMPT_BEFORE_TIMEOUT_TIME
    : idleTimerTime - PROMPT_BEFORE_TIMEOUT_TIME;
};

const IdleTimerContainer = () => {
  const { user, setUser, setAlert } = useContext(myAccountContext);
  const { configuration, setConfiguration } = useContext(configurationContext);

  const [showIdleModal, setShowIdleModal] = useState(false);
  const [idleTimerId, setIdleTimerId] = useState(0);
  const [promptTimerId, setPromptTimerId] = useState(0);

  const apiClient = useApiClient();

  // Refs are used here so that the callback timers don't have stale references.
  const userRef = useRef(user);
  const idleTimerIdRef = useRef(idleTimerId);
  const promptTimerIdRef = useRef(promptTimerId);
  const configurationRef = useRef(configuration);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    idleTimerIdRef.current = idleTimerId;
  }, [idleTimerId]);

  useEffect(() => {
    promptTimerIdRef.current = promptTimerId;
  }, [promptTimerId]);

  useEffect(() => {
    configurationRef.current = configuration;
  }, [configuration]);

  const setupIdleTimer = (syncTimers = false) => {
    let idleTimerTime: number;
    let promptTimerTime: number;

    let useSpeedyTimers = configurationRef.current.appSettings.includes('SpeedySessionTimeoutEnabled');

    if (!syncTimers) {
      localStorage.setItem('idleTimerLastRefresh', new Date().getTime().toString());
    }

    idleTimerTime = getIdleTimerTime(useSpeedyTimers, syncTimers);
    promptTimerTime = getPromptTimerTime(useSpeedyTimers, idleTimerTime);

    const idleTimerId = workerTimer.setTimeout(() => {
      console.log('const idleTimerId');
      if (userRef.current.authentication_state) {
        getAuthenticationState();
        setUser(defaultUser);
        localStorage.clear();
        setShowIdleModal(false);
        setConfiguration({
          ...configuration,
          lg_email: '',
          lg_uuid: '',
          lg_linked: '',
          lg_account_status: '',
          loginDotGovEmail: '',
          loginDotGovUuid: '',
        });
        setAlert({
          message: 'Your session expired after 20 minutes of inactivity.  Please log back in to access your account.',
          type: 'success',
        });
      }
      return workerTimer.clearTimeout(idleTimerId);
    }, idleTimerTime);
    setIdleTimerId(idleTimerId);

    const promptTimerId = workerTimer.setTimeout(() => {
      if (userRef.current.authentication_state) {
        setShowIdleModal(true);
      }
      return workerTimer.clearTimeout(promptTimerId);
    }, promptTimerTime);
    setPromptTimerId(promptTimerId);
  };

  const handleResetTimer = (syncTimers = false) => {
    setShowIdleModal(false);
    workerTimer.clearTimeout(idleTimerIdRef.current);
    workerTimer.clearTimeout(promptTimerIdRef.current);
    setupIdleTimer(syncTimers);
  };

  useEffect(() => {
    setIdleTimer({
      resetTimer: handleResetTimer,
    });
    setupIdleTimer();

    window.addEventListener('storage', (ev) => {
      // If the timer resets on another tab, sync the timer to that time
      if (ev.key === 'idleTimerLastRefresh') {
        handleResetTimer(true);
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuthenticationState = () => {
    apiClient
      .get('/authentication/state')
      .then((response) => {})
      .catch((error) => {
        if (error.response.status === 401) {
          setUser(defaultUser);
          localStorage.clear();
          setAlert({
            message: 'Your session expired after 20 minutes of inactivity.  Please log back in to access your account.',
            type: 'success',
          });
        }
      });
  };

  const title = 'Session Timeout';
  const okText = 'Continue Browsing';

  return (
    <Modal
      zIndex="z-40"
      title={title}
      visible={showIdleModal}
      onCancel={getAuthenticationState}
      onOk={getAuthenticationState}
      okButtonText={okText}
      stylingOkButton="text-sm ml-4 mb-2"
      hideCancelButton={true}
      justifyContents="justify-start"
      data-testid="timer-modal"
    >
      <p data-testid="timeout-message">
        We noticed you haven’t been very active, hence we will sign you out in 2 minutes. Please click on “Continue
        Browsing” to remain signed in.
      </p>
    </Modal>
  );
};

export default IdleTimerContainer;
