import { useState, useEffect, useContext } from 'react';
import { useApiClient } from './useApiClient';
import { defaultUser } from '../reducer/myAccountInitialState';
import { pathContext, myAccountContext, configurationContext } from '../contexts/contexts';
import { useNavigate } from 'react-router-dom';

export const useUpdateConfiguration = () => {
  const apiClient = useApiClient();
  const { setConfiguration } = useContext(configurationContext);

  return () => {
    apiClient
      .get('/configuration/state')
      .then((res) => {
        setConfiguration(res.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
};

export const useSignOut = () => {
  const { setUser, clearPersistentAlert } = useContext(myAccountContext);
  const { setUserState } = useContext(pathContext);
  const { configuration, setConfiguration } = useContext(configurationContext);

  const navigate = useNavigate();
  const apiClient = useApiClient();

  return () => {
    clearPersistentAlert();
    setUserState('');
    setConfiguration({
      ...configuration,
      loginDotGovEmail: '',
      loginDotGovUuid: '',
      lg_account_status: '',
      lg_linked: 0,
    });
    localStorage.clear();
    setUser(defaultUser);
    apiClient
      .post('/authentication/sign_out', {})
      .then((response) => {
        navigate('/sign-in', { replace: true });
      })
      .catch((error) => {
        navigate('/sign-in', { replace: true });
      });
  };
};

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}
