import React, { useContext, useEffect, useState } from 'react';
import { SectionalAlert, Button, Modal } from '../../baseComponents';
import { useApiClient } from '../../../hooks/useApiClient';

import { configurationContext, myAccountContext } from '../../../contexts/contexts';
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook';
import { useUpdateConfiguration } from '../../../hooks/hooks';

const LoginGovLinked = () => {
  const { configuration } = useContext(configurationContext);
  const { loginDotGovEmail, loginDotGovUuid } = configuration;
  const { alert, setAlert, clearPersistentAlert, user, setUser } = useContext(myAccountContext);
  const apiClient = useApiClient();
  const updateConfiguration = useUpdateConfiguration();

  PageTitleHook('USCIS Online Account | Link to Login.gov account');

  const defaultConfirmationModal = {
    title: '',
    show: false,
    keepLinkedAccount: true,
  };

  const successfulLink = alert.type === 'success';
  const [confirmationModal, setConfirmationModal] = useState(defaultConfirmationModal);

  useEffect(() => {
    updateConfiguration();
  }, []);

  const linkNewAccountBody = (
    <div className="flex flex-col space-y-2">
      <p>
        Are you sure that you want to link your new Login.gov account (<b>{user.lg_email}</b>) to your USCIS account?
      </p>
      <p>
        If you proceed by clicking on the “Yes” button, your old Login.gov account (<b>{user.linked_lg_email}</b>) will
        no longer be associated with your USCIS account.
      </p>
    </div>
  );

  const keepLinkedAccountBody = (
    <div className="flex flex-col space-y-2">
      <p>
        Are you sure that you want to keep <b>{user.linked_lg_email}</b> as your linked Login.gov account?
      </p>
    </div>
  );

  const handleLinkNewAccount = () => {
    setConfirmationModal({
      title: 'Link New Account',
      show: !successfulLink,
      keepLinkedAccount: false,
    });
  };

  const handleKeepCurrentAccount = () => {
    if (successfulLink) {
      clearPersistentAlert();
      setUser({ ...user, lg_account_linked: '' });
    } else {
      setConfirmationModal({
        title: 'Keep Current Linked Account',
        show: !successfulLink,
        keepLinkedAccount: true,
      });
    }
  };

  const handleModalCancel = () => {
    setConfirmationModal(defaultConfirmationModal);
  };

  const handleModalOk = () => {
    clearPersistentAlert();
    if (confirmationModal.keepLinkedAccount) {
      setConfirmationModal(defaultConfirmationModal);
      setUser({ ...user, lg_account_linked: '' });
    } else {
      apiClient
        .post('/login_dot_gov/update', {
          lg_email: loginDotGovEmail,
          lg_uuid: loginDotGovUuid,
        })
        .then((res) => {
          updateConfiguration();
          setUser({ ...user, lg_account_linked: '' });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setAlert({
              type: 'error',
              message: err.response.data.error,
            });
          }
        });
    }
  };

  return alert.message !== '' ? (
    <div className="card mx-auto" data-testid="login-gov-linked-container">
      <Modal
        title={confirmationModal.title}
        visible={confirmationModal.show}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        okButtonText="Yes"
        info={false}
      >
        {confirmationModal.keepLinkedAccount ? keepLinkedAccountBody : linkNewAccountBody}
      </Modal>
      <div className="mb-6">
        <h1 className="text-2xl my-3 font-normal text-dhs_font_gray">
          {successfulLink ? 'Linking Successful' : 'Linking Unsuccessful'}
        </h1>
        {
          <div data-testid="sectional-alert-container" tabIndex={0}>
            <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
            {alert.type === 'error' && (
              <div className="text-default pt-4" data-testid="login-gov-error-text">
                <p className="py-2">
                  <b className="pr-1">Your USCIS account cannot be linked to two Login.gov accounts.</b>
                  Your USCIS account already has an associated Login.gov account:{' '}
                  <b className="px-1">{user.linked_lg_email}</b>
                </p>

                <p className="py-2">
                  If you wish to keep
                  <b className="px-1">
                    <i>{user.linked_lg_email}</i>
                  </b>
                  as your linked Login.gov account, please click on the “Keep Current Linked Account” button below.
                </p>
                <p className="py-2">
                  If you still wish to link
                  <b className="px-1">
                    <i>{user.lg_email}</i>
                  </b>
                  instead, please click on the “Link New Account” button below:
                </p>
              </div>
            )}
          </div>
        }

        <div className="w-full mt-8 mb-6 flex items-center sm:flex-row flex-col button-container flex-nowrap">
          <Button
            id="continue-btn"
            text={successfulLink ? 'Continue' : 'Keep Current Linked Account'}
            className="sm:w-229 text-md"
            onMouseDown={handleKeepCurrentAccount}
            onEnter={handleKeepCurrentAccount}
          />
          {alert.type === 'error' && (
            <Button
              id="link-new-account-btn"
              text="Link New Account"
              className="sm:w-229 text-md"
              type="secondary"
              onMouseDown={handleLinkNewAccount}
              onEnter={handleLinkNewAccount}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default LoginGovLinked;
