import React, { useContext } from 'react';
import { Button, SectionalAlert } from '../../../baseComponents';

import { pathContext } from '../../../../contexts/contexts';

const InvalidIalState = ({ error = '' }) => {
  const { setUrl } = useContext(pathContext);

  function getEnvironment() {
    const environments = ['local', 'dev', 'dt', 'pt', 'preprod'];
    const origin = window.location.origin;

    for (let i = 0; i < environments.length; i++) {
      if (origin.includes(environments[i])) {
        return environments[i];
      }
    }
    return '';
  }

  const handleDone = () => {
    if (error === 'Invalid user') {
      const env = getEnvironment();
      switch (env) {
        case 'local':
        case 'dev':
        case 'dt':
          setUrl('https://qa-my.uscis.dhs.gov/');
          break;
        case 'pt':
          setUrl('https://cap-my.uscis.dhs.gov/');
          break;
        case 'preprod':
          setUrl('https://preprod-my.uscis.dhs.gov/');
          break;
        default:
          setUrl('https://my.uscis.gov/');
      }
    } else {
      setUrl('sign-in');
    }
  };

  return (
    <div className="card mx-auto" data-testid="create-account-container">
      <div className="mb-6">
        <div data-testid="sectional-alert-container" tabIndex={0}>
          <SectionalAlert type={'error'}>Invalid token</SectionalAlert>
        </div>
        <p className="font-normal py-8">Token received from USCIS is invalid</p>

        <div className="button-container mb-6">
          <Button id="ok-btn" text="OK" onMouseDown={handleDone} />
        </div>
      </div>
    </div>
  );
};
export default InvalidIalState;
