import { useEffect, useContext, useState, useCallback } from 'react';
import { configurationContext, myAccountContext } from '../../../contexts/contexts';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Footer } from '../../FooterComponents/Footer';
import { Header } from '../../HeaderComponents/Header';
import IdleTimerContainer from '../../../components/Timer/IdleTimerContainer';
import { Redirection } from '../Redirection';
import { useApiClient, setCheckForSignedInUser } from '../../../hooks/useApiClient';

export default function RootLayout() {
  const { configuration, setConfiguration } = useContext(configurationContext);
  const { setUser } = useContext(myAccountContext);

  const apiClient = useApiClient();
  const location = useLocation();
  const [configurationDataLoaded, setConfigurationDataLoaded] = useState(false);
  const [userDataLoaded, setUserDataLoaded] = useState(false);

  const isCardPage = (path: string) => {
    const fullPages = ['/dashboard', '/login-gov-faq'];
    return !fullPages.includes(path);
  };

  const { mobileView } = configuration;

  const refreshConfiguration = useCallback(async () => {
    await apiClient
      .get('/configuration/state?configuration')
      .then((res) => {
        setConfiguration(res.data);
        setConfigurationDataLoaded(true);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  const getAuthenticationState = useCallback(async () => {
    await apiClient
      .get('/authentication/state')
      .then((res) => {
        setUser(res.data);
      })
      .catch((error: any) => {
        console.log(error.response.data.error);
      })
      .finally(() => {
        // Once the app loads, all future calls need to check if there is a user for navigation purposes.
        setCheckForSignedInUser(true);
        setUserDataLoaded(true);
      });
  }, []);

  useEffect(() => {
    loadState();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadState = async () => {
    await getAuthenticationState();
    refreshConfiguration();
  };

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  if (!configurationDataLoaded || !userDataLoaded) return null;

  return (
    <div id="maincontent" data-testid="mainlayout" className={`max-w-full overflow-hidden bg-dhs_gray_20`}>
      {mobileView ? null : <IdleTimerContainer />}
      <Header />
      <main className={`${mobileView ? 'min-h-full' : 'min-h-80'} ${isCardPage(location.pathname) ? 'p-4' : ''}`}>
        <Redirection>
          <Outlet />
        </Redirection>
      </main>
      {mobileView ? null : <Footer />}
    </div>
  );
}
