import { loginGovFaqItems } from './LoginGovFaqItems';
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook';
import Accordion from '../../baseComponents/Accordion/Accordion';
import { Button } from '../../baseComponents';
import { useContext } from 'react';
import { pathContext } from '../../../contexts/contexts';

const LoginGovFaq = () => {
  PageTitleHook('USCIS Online Account | Login.gov FAQ');
  const { setUrl } = useContext(pathContext);

  return (
    <div className="flex justify-center bg-white py-8 px-8">
      <div className="w-900" data-testid="login-gov-faq-container">
        <h1 className="text-3xl my-3 font-light text-dhs_font_gray">Login.gov Frequently Asked Questions</h1>

        <div>
          <h2 className="text-2xl font-medium text-dhs_font_gray">What is Login.gov?</h2>
          <div className="pt-2 font-medium text-md text-dhs_font_gray">
            <p>
              Login.gov provides a secure and private online access option to participating government services and
              applications. With a Login.gov account, users have the capability to use the same access credentials to
              sign into multiple government websites.
            </p>
            <br></br>
            <p>
              <span className="pr-1">Like the USCIS online account, also known as</span>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://myaccount.uscis.gov/"
                className="nav-link no-underline hover:underline px-0"
              >
                myAccount
              </a>
              <span className="pr-1">, the Login.gov account meets</span>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.whitehouse.gov/briefing-room/presidential-actions/2021/05/12/executive-order-on-improving-the-nations-cybersecurity/"
                className="nav-link no-underline hover:underline px-0"
              >
                federal security standards
              </a>
              .
            </p>
          </div>
        </div>

        <Accordion items={loginGovFaqItems} />
        <Button id="2fa-back-btn" text="Back" type="primary" onClick={() => setUrl('/sign-in')} />
      </div>
    </div>
  );
};

export default LoginGovFaq;
