import React, { useEffect } from 'react';

const hostName = window && window.location && window.location.href;

export const Error404 = () => {
  useEffect(() => {
    console.log('referring url', window.location.href);
  }, []);

  return (
    <div className="card bg-white" data-testid="error404-container">
      <h2 className="p-5">ERROR: PAGE NOT FOUND</h2>
      <h2 className="p-5"> {hostName}</h2>
    </div>
  );
};
