import React from 'react';
import './SpinnerCircle.css';

export const SpinnerCircle = (props: { message?: string; size?: string; color?: string }) => {
  const { message = 'Loading. Please wait...', size = '50', color = 'text-dhs-blue' } = props;
  return (
    <div data-testid="spinner-div" className="my-5 py-3 flex flex-col justify-center items-center">
      <svg
        width={size}
        height={size}
        className={`${color} animate-spin 2s linear infinite `}
        viewBox="-25 -25 250 250"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          stroke="#e0e0e0"
          strokeWidth="22px"
          strokeDasharray="565.48px"
          strokeDashoffset="0"
        ></circle>
        <circle
          r="90"
          cx="100"
          cy="100"
          stroke="currentColor"
          strokeWidth="22px"
          strokeLinecap="round"
          strokeDashoffset="118.692px"
          fill="transparent"
          strokeDasharray="565.48px"
        ></circle>
      </svg>
      {message.length > 0 && <h2 className={`${color} my-3`}>{message}</h2>}
    </div>
  );
};
