import { PencilIcon, EyeIcon } from '../../../assets/icons';

interface EditAccountRowProps {
  label: string | null | undefined;
  value?: string | null | undefined;
  buttonClass?: string;
  buttonText: string;
  onClick: Function;
  testId?: string;
}

export const EditAccountRow = ({
  label,
  value = '',
  onClick,
  buttonClass,
  buttonText,
  testId = 'edit-account-row-btn',
}: EditAccountRowProps) => {
  const isView = buttonText.toLowerCase().includes('view');

  return (
    <div
      className={`flex flex-row py-3 justify-between align-middle items-center border-b-2 border-gray-200`}
      data-testid="edit-account-row-container"
    >
      <div className="w-4/5 sm:w-3/4 flex flex-col justify-between sm:flex-row">
        <div className={`font-bold sm:w-1/2 whitespace-nowrap`}>{label}</div>
        <div className="sm:w-1/2 break-words color-light-grey">{value}</div>
      </div>

      <div className="w-1/5 sm:w-1/4 justify-start text-right">
        <button
          data-testid={testId}
          aria-label={`${label} ${value} ${buttonText} `}
          className={`text-dhs_light_blue font-bold ${buttonClass?.length ? `edit-account-button-${buttonClass}` : ''}`}
          onClick={() => onClick()}
        >
          <div className="edit-account-button p-0 sm:px-2 sm:py-1 flex justify-end items-center ">
            {isView ? <EyeIcon color="#006699" height="14" /> : <PencilIcon color="#006699" height="14" />}
            <span className="pl-1 hidden sm:inline">{buttonText}</span>
          </div>
        </button>
      </div>
    </div>
  );
};
