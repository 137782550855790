import React, { useContext } from 'react';
import { Button } from '../../../baseComponents';
import { pathContext, myAccountContext } from '../../../../contexts/contexts';
import { IDPCard } from '../IDPCard';

export const IDPIndex = () => {
  const { setUrl } = useContext(pathContext);
  const { user, setUser } = useContext(myAccountContext);

  const handleTakeQuiz = () => {
    setUrl('/idp/question');
  };

  const handleCancel = () => {
    setUser({ ...user, ial_state: 'canceled_quiz' });
  };

  return (
    <>
      {user.ial_state === 'awaiting_quiz' ? (
        <IDPCard title="We need to confirm your identity.">
          <p>
            To protect your information, we need to confirm that you are who you say you are. Only you, the immigrant
            receiving a Green Card, are allowed to create a USCIS online account.
          </p>
          <p>
            Attorneys, accredited representatives, preparers, interpreters, or notaries cannot create a USCIS online
            account for you. If you are not the immigrant who will receive the Green Card, please click <b>Cancel</b> to
            exit this screen.
          </p>
          <p>
            Once you click <b>Get Started</b>, we will ask you a series of questions to confirm your identity. You may
            find it helpful to refer to the following documents before you begin:
          </p>
          <ul className="idp-card-bullet">
            <li>Your passport(s)</li>
            <li>A copy of your visa application(s)</li>
            <li>Your Immigrant Data Summary Sheet</li>
          </ul>
          <p>You will have 60 minutes to complete the quiz.</p>
          <h2 className="text-xl">Are you ready to start?</h2>
          <div className="justify-start flex space-x-4 pt-6 pb-0">
            <Button id="start-quiz-btn" text="Get started!" onClick={handleTakeQuiz} />
            <Button id="cancel-quiz-btn" text="Cancel" type="cancel" onClick={handleCancel} />
          </div>
        </IDPCard>
      ) : null}
      {user.ial_state === 'started_quiz' ? (
        <IDPCard title="Continue your identity quiz?">
          <p>
            It appears you have not successfully completed an identity quiz. Do you want to continue confirming your
            identity?
          </p>
          <h3 className="text-xl">Are you ready to start?</h3>
          <div className="justify-start flex space-x-4 pt-6 pb-0">
            <Button id="start-quiz-btn" text="Retry Quiz" onClick={handleTakeQuiz} />
            <Button id="cancel-quiz-btn" text="Cancel" type="cancel" onClick={handleCancel} />
          </div>
        </IDPCard>
      ) : null}
    </>
  );
};
