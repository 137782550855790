// I couldn't  get the worker to run in a seperate file with the bundler. So we use a blob here.
const workerBlob = new Blob([
  `
  let intervalIds = {}
  let timeoutIds = {}
  self.onmessage = function (e) {
    switch (e.data.command) {
      case 'interval:start':
        var intvalId = setInterval(function () {
          postMessage({
            message: 'interval:tick',
            id: e.data.id,
          })
        }, e.data.interval)

        postMessage({
          message: 'interval:started',
          id: e.data.id,
        })

        intervalIds[e.data.id] = intvalId
        break

      case 'timeout:start':
        var intvalId = setTimeout(function () {
          postMessage({
            message: 'timeout:tick',
            id: e.data.id,
          })
        }, e.data.time)

        postMessage({
          message: 'timeout:started',
          id: e.data.id,
        })

        timeoutIds[e.data.id] = intvalId
        break

      case 'interval:clear':
        clearInterval(intervalIds[e.data.id])

        postMessage({
          message: 'interval:cleared',
          id: e.data.id,
        })

        delete intervalIds[e.data.id]
        break
      case 'timeout:clear':
        clearTimeout(timeoutIds[e.data.id])

        postMessage({
          message: 'timeout:cleared',
          id: e.data.id,
        })

        delete timeoutIds[e.data.id]
        break
    }
  }

`,
]);

let worker: any;

try {
  worker = new Worker(URL.createObjectURL(workerBlob));
} catch (error) {
  worker = {
    onmessage: () => {},
    postMessage: () => {},
  };
}

type WorkerTimerType = {
  id: number;
  callbacks: any;
  setInterval: Function;
  setTimeout: Function;
  clearInterval: Function;
  clearTimeout: Function;
  onMessage: Function;
};

let workerTimer: WorkerTimerType = {
  id: 0,
  callbacks: {},

  setInterval: function (cb: any, interval: number, context: any) {
    this.id++;
    let id = this.id;
    this.callbacks[id] = { fn: cb, context: context };
    worker.postMessage({
      command: 'interval:start',
      interval: interval,
      id: id,
    });
    return id;
  },

  setTimeout: function (cb: any, time: number) {
    this.id++;
    let id = this.id;
    this.callbacks[id] = { fn: cb };
    worker.postMessage({
      command: 'timeout:start',
      time,
      id,
    });
    return id;
  },

  onMessage: function (e: any) {
    let callback = this.callbacks[e.data.id];
    switch (e.data.message) {
      case 'interval:tick':
        if (callback && callback.fn) callback.fn.apply(callback.context);
        break;
      case 'interval:cleared':
        delete this.callbacks[e.data.id];
        break;
      case 'timeout:tick':
        if (callback && callback.fn) callback.fn.apply(callback.context);
        break;
      case 'timeout:cleared':
        delete this.callbacks[e.data.id];
        break;
      default:
    }
  },

  clearInterval: function (id: number) {
    worker.postMessage({ command: 'interval:clear', id: id });
  },

  clearTimeout: function (id: number) {
    worker.postMessage({ command: 'timeout:clear', id });
  },
};

worker.onmessage = workerTimer.onMessage.bind(workerTimer);

export default workerTimer;
