import React, { useEffect, useState } from 'react';

export const LastUsed = (props: { timestamp?: string | '' }) => {
  const { timestamp } = props;
  const [timeInWords, setTimeInWords] = useState<string>('');

  useEffect(() => {
    if (!timestamp?.length) {
      return;
    }

    const dateFromTimestamp = new Date(timestamp);
    const minutesAgo = getMinutesAgo(dateFromTimestamp);

    if (minutesAgo <= 1) {
      setTimeInWords('1 minute ago.');
    } else if (minutesAgo <= 59) {
      setTimeInWords(`${minutesAgo} minutes ago.`);
    } else if (minutesAgo <= 119) {
      setTimeInWords('1 hour ago.');
    } else if (minutesAgo <= 1440) {
      const hoursAgo = Math.floor(minutesAgo / 60);
      setTimeInWords(`${hoursAgo} hours ago.`);
    } else {
      setTimeInWords(
        new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(dateFromTimestamp)
      );
    }
  }, [timestamp]);

  const getMinutesAgo = (datetime: Date) => {
    const now = new Date();

    const millisecondsAgo = now.getTime() - datetime.getTime();
    const millisecondsPerMinute = 1000 * 60;

    return Math.floor(millisecondsAgo / millisecondsPerMinute);
  };

  return <>Last used: {timeInWords}</>;
};

export default LastUsed;
